import { useContext, useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { LoadingBtn } from "../../../components/laoding/laoding"
import axios from "axios"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { ChatContext } from "../../../components/context/ChatContext"




const Create5Offers = () => {
    const { t } = useTranslation()
    const userData = useSelector((state: RootState) => state.userData)

    const [photo, setPhoto] = useState<File[]>([])
    const [errInput, setErrInput] = useState<string>('')
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Create Offer"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [statusBtnPatch, setStatusBtnPatch] = useState<any>(t("Home.Account.change"))
    const [disabledBtnPatch, setDisabledBtnPatch] = useState<boolean>(false)
    const [data5Offers, setData5Offers] = useState<string[]>()
    const [id5Offers, setId5Offers] = useState<string>()
    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Other/Get5Offers`)
            .then((doc) => { setData5Offers(doc.data[0].files5Offer); setId5Offers(doc.data[0]._id) })
            .catch((err) => console.log(err))
    }, [])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }

    const BtnPatch = async () => {
        setStatusBtnPatch(<LoadingBtn />)
        setDisabledBtnPatch(true)
        if (photo.length == 0) {
            setDisabledBtnPatch(false)
            setStatusBtnPatch(t("Home.Account.change"))
            setErrInput(t("Market.Please enter photo"))
            return;
        }
        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }

        const updatedFormData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Other/Patch5Offers/${id5Offers}`, updatedFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData.user.token,
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnPatch(t("Home.Account.change"))
                setDisabledBtnPatch(false)
            })
    }

    const BtnCreate = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        if (photo.length == 0) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Offer"))
            setErrInput(t("Market.Please enter photo"))
            return;
        }
        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }

        const updatedFormData = new FormData();

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Other/Post5Offers`, updatedFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData.user.token,
                }
            })
            .then((doc) => console.log(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnCreate(t("Market.Create Offer"))
                setDisabledBtnCreate(false)
            })
    }

    const changeImageOrder = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photo];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhoto(newPhotos);
    }

    return (<>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: isMobile ? "auto" : "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: isMobile ? 10 : "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: isMobile ? "40px 10px" : "20px 10% 0 10%" }}>
            <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>الصور الثابتة</h1>
            <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                {data5Offers?.[0] && <img draggable={false} style={{ width: isMobile ? 60 : 100, height: isMobile ? 60 : 100 }} src={`${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[0] : ""}`} />}
                {data5Offers?.[1] && <img draggable={false} style={{ width: isMobile ? 60 : 100, height: isMobile ? 60 : 100 }} src={`${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[1] : ""}`} />}
                {data5Offers?.[2] && <img draggable={false} style={{ width: isMobile ? 60 : 100, height: isMobile ? 60 : 100 }} src={`${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[2] : ""}`} />}
                {data5Offers?.[3] && <img draggable={false} style={{ width: isMobile ? 60 : 100, height: isMobile ? 60 : 100 }} src={`${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[3] : ""}`} />}
                {data5Offers?.[4] && <img draggable={false} style={{ width: isMobile ? 60 : 100, height: isMobile ? 60 : 100 }} src={`${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[4] : ""}`} />}
            </div>
            <div style={{ width: "100%", maxWidth: "1000px" }}>
                <Form.Group className="mb-3" controlId="exampleForm.Photos">
                    <Form.Label>{t("Market.Photo")} :</Form.Label>
                    <Form.Control type="file" onChange={handelPhoto} multiple />
                </Form.Group>

            </div>
            <div>
                {photo.length > 0 &&
                    <div style={{ display: "flex", gap: 40, height: 250, }}>
                        <h3>الصور التي تم اختيارها:</h3>
                        {photo.map((i, index) => (
                            <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                                <p>الصورة {index + 1}: {i.name}</p>
                                <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" height={100} />
                                <div style={{ display: "flex", gap: 10 }}>
                                    {index > 0 && (
                                        <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index - 1)}>
                                            <FaArrowUp size={20} />
                                        </Button>
                                    )}
                                    {index < photo.length - 1 && (
                                        <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index + 1)}>
                                            <FaArrowDown size={20} />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div>
                <div style={{ color: "red" }}>{errInput}</div>
                {data5Offers?.length! > 0 ?
                    <Button bsPrefix="BtnBNB" onClick={BtnPatch} disabled={disabledBtnPatch}>{statusBtnPatch}</Button>
                    :
                    <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                }
            </div>


        </div>
    </>)
}

export default Create5Offers;