import { useContext } from "react";
import { ChatContext } from "../context/ChatContext";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UsersProps } from "../../Interfaces";




const PotentialChats: React.FC = () => {
    const chatContext = useContext(ChatContext);
    const userData = useSelector((state: RootState) => state.userData);

    const { potentialChats, createChat, onlineUsers } = chatContext!
    return (<>
        <div style={{ display: "flex", gap: 10, margin: "10px 10px 0 10px" }}>
            {
                potentialChats?.map((user: UsersProps, index: number) => {
                    return <div key={index} style={{ padding: '10px', backgroundColor: "rgb(181 200 255)", display: "flex", borderRadius: "10px", cursor: "pointer", position: "relative" }} onClick={() => createChat!(userData?.user?.userDetals?._id!, user?._id!)}>
                        {user.userName}
                        {
                            onlineUsers?.some((onlineUser) => onlineUser?.userId === user?._id) ?
                                <span style={{ width: "10px", height: "10px", backgroundColor: "green", borderRadius: "50%", position: "absolute", top: "-0px", left: "-5px" }}></span>
                                :
                                <span></span>
                        }
                    </div>
                })
            }
        </div>
    </>)
}

export default PotentialChats;