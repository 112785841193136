import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { OrderProps } from "../../admin/market/orders/getOrders/GetOrders.admin";
import { Button, Table } from "react-bootstrap";
import Langu from "../../components/languge/languge";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LoadingBtn } from "../../components/laoding/laoding";
import { ChatContext } from "../../components/context/ChatContext";



const MyOrders: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))
    const [limit, setLimit] = useState(20)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrdersUserId/${userData?.user?.userDetals?._id}/${limit}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => setDataOrders(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnViewMore(t("Market.View more"))
            })
    }, [limit])

    const BtnViewMore = () => {
        setStatusBtnViewMore(<LoadingBtn />)
        setLimit(limit + 10)
    }

    return (<>
        <div style={{
            // backgroundImage: dataCategory && `url(${process.env.REACT_APP_URL_API}/API/files/Categorys/${dataCategory?.file?.[0]})`,
            minHeight: "20vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundBlendMode: "darken",
        }}>
            <div className="nameOffer" style={{ top: "calc(10vh + 100px)" }}>{t("Hedar.my Orders")}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", maxWidth: "100%" }}>
            <div style={{ maxWidth: "1440px", padding: isMobile ? 0 : "0 40px", flexDirection: "column" }}>
                <Table style={{ border: "1px solid #dddddd" }}>
                    <thead style={{ maxWidth: "100%" }}>
                        <tr>
                            <th style={{ textAlign: "start", backgroundColor: "#000b29", color: "white" }}>#</th>
                            <th style={{ textAlign: "center", backgroundColor: "#000b29", color: "white" }}>{t("Market.product")}</th>
                            <th style={{ textAlign: "center", backgroundColor: "#000b29", color: "white" }}>{t("Market.Total price")}</th>
                            {/* <th style={{ textAlign: "center", backgroundColor: "#000b29", color: "white" }}>{t("Market.PayPage.Payment methods")}</th> */}
                            <th style={{ textAlign: "center", backgroundColor: "#000b29", color: "white" }}>{t("Market.Timing")}</th>
                            <th style={{ textAlign: "center", backgroundColor: "#000b29", color: "white" }}>{t("Market.status")}</th>
                            <th style={{ textAlign: "end", backgroundColor: "#000b29", color: "white" }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ maxWidth: "100%" }}>
                        {
                            dataOrders?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).map((order, index) =>
                                <tr key={index} >
                                    <td style={{ alignContent: "center", fontWeight: "500", textAlign: "start" }}>{index + 1}</td>
                                    <td style={{ alignContent: "center", fontWeight: "500", textAlign: "center" }}>
                                        <div >
                                            {order.dataItems?.map((item, index) =>
                                                <div key={index}>
                                                    <Langu  {...item.product!} /> × {item.quantity}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ alignContent: "center", fontWeight: "500", textAlign: "center" }}>{order.totalPrice} ₺</td>
                                    {/* <td style={{ alignContent: "center", fontWeight: "500", textAlign: "center" }}>{t(`Market.PayPage.${order.paymentMethod}`)}</td> */}
                                    <td style={{ alignContent: "center", fontWeight: "500", textAlign: "center" }}>{new Date(order?.createdAt!).toLocaleString('en-GB', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    })}</td>
                                    <td style={{ alignContent: "center", fontWeight: "500", textAlign: "center" }}>{t(`Market.PayPage.${order.status}`)}</td>
                                    <td style={{ alignContent: "center", textAlign: "end" }}><Button bsPrefix="BtnBNB" onClick={() => nav(`/client/myAccount/orderDetals/${order._id}`)}>{t("Market.Order details")}</Button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <div style={{ width: "100%", textAlign: "center", padding: isMobile ? 20 : 40 }}>
                    <Button bsPrefix="BtnBNB" onClick={BtnViewMore}>{statusBtnViewMore}</Button>
                </div>
            </div>
        </div >
    </>)
}

export default MyOrders;