import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../../../components/laoding/laoding";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

interface BtnComplete {
    status: string;
    id: string;
    onRefund: () => void;
    paymentMethod?: string;
    dataItems: any;
}

const BtnComplete: React.FC<BtnComplete> = ({ status, id, onRefund, paymentMethod, dataItems }) => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()

    const [statusBtn, setStatusBtn] = useState<any>(t("Market.PayPage.complete"))
    const [disabled, setDisabled] = useState<boolean>(false)

    const btnComplete = async () => {
        setDisabled(true)
        setStatusBtn(<LoadingBtn />)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Orders/PutStatusOrder`, { status, id, paymentMethod, dataItems },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .catch((err) => console.log(err))
        onRefund()
        setStatusBtn(t("Market.PayPage.complete"))
        setDisabled(false)
    }

    return (<>
        {(status !== "delivery done" && status !== "canceled") &&
            <Button onClick={btnComplete} disabled={disabled} bsPrefix="BtnBNB">{statusBtn}</Button>
        }
    </>)
}

export default BtnComplete;