import { Outlet } from "react-router-dom";
import { motion } from 'framer-motion';
import HedarClient from "../hedarClient/HedarClient";
import FloatingButtonClient from "../components/supportIcon/SupportIconClient";
import Footer from "../../components/footer/Footer";


const pageTransition = {
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: "-100%" }
};

const OutletClient: React.FC = () => {


    return (<>
        <HedarClient />
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={pageTransition}
            transition={{ duration: 0.1 }}
        >
            <Outlet />
            <FloatingButtonClient />
            <Footer />
        </motion.div>

    </>)
}

export default OutletClient;