import Offer1Client from "./Offer1.client";
import AllProductsClient from "./AllProducts.client";



const HomeClient: React.FC = () => {
    return (<>
        <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Offer1Client />
            </div>
            <div>
                <AllProductsClient />
            </div>
        </div>
    </>)
}

export default HomeClient;