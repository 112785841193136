import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { LoadingBtn } from "../../../components/laoding/laoding"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import GetBrandsAndDelete from "./GetBrandsAndDelete"
import { ChatContext } from "../../../components/context/ChatContext"

const CreateBrand: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Create Brand"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [nameAR, setNameAR] = useState<string>('')
    const [nameEN, setNameEN] = useState<string>('')
    const [nameHE, setNameHE] = useState<string>('')
    const [categoryName, setCategoryName] = useState<string>('')
    const [errInput, setErrInput] = useState<string>('')
    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setCategoryName(JSON.stringify({
                        en: response.data[0].name.en,
                        ar: response.data[0].name.ar,
                        he: response.data[0].name.he
                    }));
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        setErrInput('')
    }, [nameAR, nameEN, nameHE])


    const BtnCreate = async () => {
        setDisabledBtnCreate(true)
        setStatusBtnCreate(<LoadingBtn />)
        if (!nameAR || !nameEN || !nameHE) {
            setErrInput(t("Please enter data"))
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Brand"))
            return;
        }
        const Category = JSON.parse(categoryName)

        const dataPost = { nameEN, nameAR, nameHE, categoryEN: Category.en, categoryAR: Category.ar, categoryHE: Category.he }
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Brands/PostBrand`, dataPost,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(doc => { { console.log(doc) } { setStatusBtnCreate(t("Market.Create Brand")) } { setDisabledBtnCreate(false) } })
            .catch(err => console.log(err))
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: isMobile ? 10 : "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: isMobile ? "40px 10px" : "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Create Brand")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Brand name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" onChange={e => { setNameEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setNameAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="Türkçe" onChange={e => { setNameHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div>
                    <div style={{ color: "red" }}>{errInput}</div>
                    <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                </div>
                <GetBrandsAndDelete refreshData={disabledBtnCreate} />
            </div>
        </div>
    </>)
}

export default CreateBrand;