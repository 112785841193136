import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useContext, useEffect, useRef, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { UseFetchRecipientUser } from "./UseFetchRecipientUser";
import { Loading } from "../../components/laoding/laoding";
import { Button, Stack } from "react-bootstrap";
import moment from "moment";
import './Chat.css'
import InputEmojiWithRef from "react-input-emoji";
import { BsFillSendFill } from "react-icons/bs";
import { UnreadNotification } from "./UnradeNotification";



const ChatBox: React.FC = () => {
    const userId = useSelector((state: RootState) => state.userData).user.userDetals
    const chatContext = useContext(ChatContext);
    const [message, setMessage] = useState<string>('')
    const scroll = useRef<HTMLDivElement | null>(null)
    const { currentChat, messages, isMessagesLoading, sendMessage, notificationDB, markThisUserNotificationsAsRead, notification } = chatContext!;
    const { recipientUser } = UseFetchRecipientUser({ chat: currentChat, user: userId })

    const unreadNotification = UnreadNotification({ notificationDB })
    const ThisUserNotifications = unreadNotification.filter((n) => n.senderId === recipientUser?._id)
    useEffect(() => {
        scroll.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages])


    const handleKeyPressLogin = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            sendMessage && sendMessage(message, userId, currentChat?._id!, setMessage)
        }
    };

    if (!recipientUser) return null
    if (isMessagesLoading) return <Loading />
    return (<>
        <Stack style={{ width: "70%", maxHeight: "70vh" }}>
            <div style={{ textAlign: "center", minHeight: "60px", alignContent: "center", backgroundColor: "#dddddd" }}>
                <strong style={{ fontSize: "25px" }}>{recipientUser.userName}</strong>
            </div>
            <Stack gap={2} style={{ border: "1px solid #dddddd", overflowY: "auto", padding: " 20px" }}>
                {messages && messages.map((message, index) => (
                    <Stack key={index}
                        className={`${message?.senderId === userId?._id ? "message self align-self-end flex-grow-0" : "message align-self-start flex-grow-0"}`}
                        ref={scroll}
                    >
                        <span>{message.text}</span>
                        <span style={{ fontSize: "12px" }}>{moment(message.createdAt).calendar()}</span>
                    </Stack>
                ))}
            </Stack>
            <Stack direction="horizontal" gap={3} style={{ padding: "0 10px" }}>
                <InputEmojiWithRef onKeyDown={handleKeyPressLogin} placeholder="Message" value={message} onChange={setMessage} shouldReturn={false} shouldConvertEmojiToImage={false} />
                <Button bsPrefix="BtnBNB" onClick={() => { sendMessage!(message, userId, currentChat?._id!, setMessage); markThisUserNotificationsAsRead(ThisUserNotifications, notification) }}><BsFillSendFill size={20} /></Button>
            </Stack>
        </Stack>
    </>)
}

export default ChatBox