import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './NavAdmin.css'
import { useNavigate } from "react-router";
import Langu from "../../../components/languge/languge";
import { ThemeColor } from "../../../theme";
import { Brand, Category } from "../../../Interfaces";

const SidebarMenuAdmin = () => {
    const { t, i18n } = useTranslation()
    const nav = useNavigate()
    const [categorys, setCategorys] = useState<Category[]>()
    const [category, setCategory] = useState<Category | null>(null)
    const [dataBrands, setDataBrands] = useState<Brand[]>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setCategorys(doc.data))
            .catch((err) => console.log(err))

        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => { setDataBrands(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    return (
        <div style={{ position: "relative" }} onMouseLeave={() => setCategory(null)}>
            <div style={{ display: "flex", border: "2px solid #dddddd", width: 200, height: "98vh", marginTop: "2vh", flexDirection: "column", overflowX: "auto" }}>
                <div style={{ fontSize: 25, fontWeight: 600, textAlign: "center", padding: 20 }}>
                    {t("Home.Our varieties")}
                </div>
                {categorys?.map((cate, index) => (
                    <div key={index}
                        onMouseEnter={() => setCategory(cate)}
                        style={{ width: "100%" }}
                    >
                        <div
                            style={{ padding: "10px 20px", width: "100%", textAlign: "start", backgroundColor: category === cate ? ThemeColor.bgColor(0.05) : "", cursor: "pointer", fontSize: 20, display: "flex", gap: 10, alignItems: "center" }}
                            onClick={() => nav(`/admin/market/category/${cate.name.en}`)}
                        >
                            <img draggable={false} style={{ width: 20, height: 20 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${cate.file ? cate.file?.[2] : ""}`} />
                            <Langu {...cate.name} />
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
                {
                    category &&
                    <div
                        style={{
                            position: "absolute",
                            left: i18n.dir() === "rtl" ? 0 : "100%",
                            right: i18n.dir() === "ltr" ? 0 : "100%",
                            top: -2,
                            zIndex: 3,
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid #dddddd",
                            padding: "16px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            opacity: 1,
                            transition: "opacity 0.3s ease-in-out",
                            width: 600,
                            height: "98vh",
                            display: "flex",
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <div>
                                <p style={{ color: ThemeColor.text, fontSize: "20px", fontWeight: 700 }}>
                                    {t('Market.brand')} :
                                </p>
                                {
                                    dataBrands?.map((brand, index) => (
                                        <div className="ItemMenue" onClick={() => nav(`/admin/market/category/${category.name.en}/brand/${brand.name.en}`)} key={index}>
                                            <Langu {...brand.name} />
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                category.classification.length > 0 &&
                                <div>
                                    <p style={{ color: ThemeColor.text, fontSize: "20px", fontWeight: 700 }}>
                                        {t(`Hedar.Notifications.Usage`)} :
                                    </p>
                                    {
                                        category.classification.map((item, index) => (
                                            <div className="ItemMenue" onClick={() => nav(`/admin/market/category/${category.name.en}/classification/${item}`)} key={index}>
                                                {t(`Market.${item}`)}
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                category.statusCategory.length > 0 &&
                                <div>
                                    <p style={{ color: ThemeColor.text, fontSize: "20px", fontWeight: 700 }}>
                                        {t(`Hedar.Notifications.status`)} :
                                    </p>
                                    {
                                        category.statusCategory.map((item, index) => (
                                            <div className="ItemMenue" onClick={() => nav(`/admin/market/category/${category.name.en}/statusCategory/${item}`)} key={index}>
                                                {t(`Market.${item}`)}
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                category.Categorys?.map((item, index) => (
                                    <div key={index}>
                                        <p style={{ color: ThemeColor.text, fontSize: "20px", fontWeight: 700 }}>
                                            <Langu {...item.name} /> :
                                        </p>
                                        {item.Categories.map((Categorie, index) => (
                                            <div className="ItemMenue" onClick={() => nav(`/admin/market/category/${category.name.en}/categorys/${[item.name.en, Categorie.en]}`)} key={index}>
                                                <Langu {...Categorie} />
                                            </div>
                                        ))}
                                    </div>
                                ))
                            }
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ position: "absolute", bottom: 20, left: 20 }}>
                                <img draggable={false} style={{ width: 300, height: 300 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${category.file ? category.file[0] : ""}`} />
                            </div>
                        </div>
                    </div>

                }
            </div>
        </div>
    );
};

export default SidebarMenuAdmin;
