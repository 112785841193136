import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OrderProps } from "../../../../admin/market/orders/getOrders/GetOrders.admin";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useState } from "react";
import { LoadingBtn } from "../../../../components/laoding/laoding";


interface dataOrders {
    dataOrder: OrderProps;
    onRefund: () => void;
}

const RefundCash: React.FC<dataOrders> = ({ dataOrder, onRefund }) => {
    const { t } = useTranslation()
    const userData = useSelector((state: RootState) => state.userData);
    const [stautsBtn, setStatusBtn] = useState<any>(t("Market.PayPage.Cancel order"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)

    const BtnRefundCash = async () => {
        setDisabledBtn(true)
        const NewDataOrder: OrderProps = await axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrder/${dataOrder._id}`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
        const dataRefundOrder = { dataOrder: NewDataOrder.data, cancelOrder: true, status: "canceled", role: userData.user.userDetals?.role }
        if (NewDataOrder.data.status === "new" || NewDataOrder.data.status === "viewed" || userData.user.userDetals?.role === true) {
            setStatusBtn(<LoadingBtn />)
            await axios.put(`${process.env.REACT_APP_URL_API}/API/Orders/RefundOrder`, dataRefundOrder,
                {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    }
                }
            )
                .catch((err) => console.log(err))
        }
        onRefund()
        setDisabledBtn(false)
        setStatusBtn(t("Market.PayPage.Cancel order"))
    }

    return (<>
        <Button variant="danger" onClick={BtnRefundCash} disabled={disabledBtn}>{stautsBtn}</Button>
    </>)
}

export default RefundCash;