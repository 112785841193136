import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import SidebarMenuClient from "../components/NavClient/NavClient";
import { Offers } from "../../Interfaces";
import { ChatContext } from "../../components/context/ChatContext";


const Offer1Client: React.FC = () => {

    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!
    const [numImg, setNumImg] = useState<number>(0);
    const [dataOffers, setDataOffers] = useState<Offers[] | null>(null);

    const [data5Offers, setData5Offers] = useState<string[]>()



    useEffect(() => {
        // جلب البيانات من API
        axios
            .get(`${process.env.REACT_APP_URL_API}/API/Offers/GetOffers`)
            .then((doc) => setDataOffers(doc.data))
            .catch((err) => console.log(err));
        axios.get(`${process.env.REACT_APP_URL_API}/API/Other/Get5Offers`)
            .then((doc) => { setData5Offers(doc.data[0].files5Offer) })
            .catch((err) => console.log(err))
    }, []);

    useEffect(() => {
        // تحقق من وجود البيانات
        if (!dataOffers || dataOffers.length === 0) return;

        // إعداد المؤقت لتغيير الصور
        const interval = setInterval(() => {
            setNumImg((prevIndex) => (prevIndex + 1) % dataOffers.length);
        }, 7000); // المدة الزمنية (7 ثواني)

        // تنظيف المؤقت عند إزالة الكومبوننت أو إعادة تشغيله
        return () => clearInterval(interval);
    }, [dataOffers]);

    return (

        <div
            style={{
                width: "100%",
                maxWidth: 1660,
                height: "auto",
                minHeight: isMobile ? "72vh" : "100vh",
                maxHeight: "100vh",
                display: "flex",
                padding: isMobile ? "0 10px" : "0 40px",
            }}
        >
            {!isMobile &&
                <div style={{ maxHeight: "100%" }}>
                    <SidebarMenuClient />
                </div>
            }

            {
                isMobile ?
                    <div style={{ width: "100%", display: 'flex', flexDirection: "column", margin: "2vh 0px 0px 0px" }}>
                        <div style={{ position: "relative", width: "100%", height: "28vh" }}>
                            {dataOffers?.map((product, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: index === numImg ? 1 : 0,
                                    }}
                                    transition={{ duration: 1 }}
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${product?.file ? product.file[0] : ""
                                            })`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                        border: "1px solid #dddddd",
                                    }}
                                />
                            ))}
                        </div>
                        <div style={{ height: "18vh", display: "flex", width: "100%", gap: 5, marginTop: 5 }}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                }}
                                transition={{ duration: 1 }}
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[0] : ""
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid #dddddd",
                                }}
                            />

                            <motion.div
                                transition={{ duration: 1 }}
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[1] : ""
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid #dddddd",
                                }}
                            />
                        </div>
                        <div style={{ height: "18vh", display: "flex", width: "100%", gap: 5, marginTop: 5 }}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                }}
                                transition={{ duration: 1 }}
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[2] : ""
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid #dddddd",
                                }}
                            />

                            <motion.div
                                transition={{ duration: 1 }}
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[3] : ""
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid #dddddd",
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div style={{ width: "100%", gap: 10, display: 'flex', flexDirection: "column", margin: "2vh 10px 0px 10px" }}>
                        <div style={{ width: "100%", height: "60%", display: "flex", gap: 10 }}>
                            <div style={{ position: "relative", height: "100%", width: "70%" }}>
                                {dataOffers?.map((product, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0 }}
                                        animate={{
                                            opacity: index === numImg ? 1 : 0,
                                        }}
                                        transition={{ duration: 1 }}
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${product?.file ? product.file[0] : ""
                                                })`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            width: "100%",
                                            height: "100%",
                                            position: "absolute",
                                            border: "1px solid #dddddd",
                                        }}
                                    />
                                ))}
                            </div>
                            <div style={{ height: "100%", display: "flex", width: "30%", flexDirection: "column", gap: 10 }}>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: 1,
                                    }}
                                    transition={{ duration: 1 }}
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[0] : ""
                                            })`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "50%",
                                        border: "1px solid #dddddd",
                                    }}
                                />

                                <motion.div
                                    transition={{ duration: 1 }}
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[1] : ""
                                            })`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "50%",
                                        border: "1px solid #dddddd",
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%", height: "40%", display: "flex", gap: 10 }}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    zIndex: 1, // التأكد من أن الصورة النشطة تظهر في الأمام
                                }}
                                transition={{ duration: 1 }}
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[2] : ""
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: `33.3%`,
                                    height: "100%",
                                    border: "1px solid #dddddd",
                                }}
                            />

                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    zIndex: 1, // التأكد من أن الصورة النشطة تظهر في الأمام
                                }}
                                transition={{ duration: 1 }}
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[3] : ""
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: `33.3%`,
                                    height: "100%",
                                    border: "1px solid #dddddd",
                                }}
                            />

                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    zIndex: 1, // التأكد من أن الصورة النشطة تظهر في الأمام
                                }}
                                transition={{ duration: 1 }}
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Offers/${data5Offers ? data5Offers?.[4] : ""
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: `33.3%`,
                                    height: "100%",
                                    border: "1px solid #dddddd",
                                }}
                            />
                        </div>
                    </div>
            }
        </div>
    )
}

export default Offer1Client;