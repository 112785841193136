import { Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import './Hedar.client.css'
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Cookies from "universal-cookie";
import { isExpired } from "react-jwt";
import axios from "axios";
import { setUser } from "../../redux/userSlice";
import I18nClient from "./I18n.client";
import { useTranslation } from "react-i18next";
import AccuntDropdownClient from "./AccuntDropdown.client";
import { MdOutlineShoppingCart } from "react-icons/md";
import ShoppingCart from "../../guest/hedarGuest/ShoppingCart";
import Langu from "../../components/languge/languge";
import SearchBar from "../../components/searchBar/SearchBar";
import { ThemeColor } from "../../theme";
import { GrFavorite } from "react-icons/gr";
import { Category } from "../../Interfaces";
import img1 from '../../files/HomeGuest/شعار أبو أحمد معدل مقصوص.jpg'
import { ChatContext } from "../../components/context/ChatContext";
import { FaBars } from "react-icons/fa";
import img2 from '../../files/HomeGuest/شعار خلفية حمرة معدل.jpg'
import img3 from '../../files/HomeGuest/IconHome2.png'



const HedarClient: React.FC = () => {

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!
    const { t } = useTranslation()
    const nav = useNavigate()
    const cookie = new Cookies()
    const getTokenCookie = cookie.get("bearer");

    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)
    const [dataCategotys, setDataCategorys] = useState<Category[]>()
    const [menuVisible, setMenuVisible] = useState<boolean>(false);

    let TokenReducer = userData?.user?.token
    let IsExpiredReducerToken = isExpired(TokenReducer!)
    let RefreshToken = getTokenCookie?.refreshToken

    const quantitys = CarentItem?.cartItems.reduce((total, cartitem) => {
        return total + cartitem.quantity
    }, 0)


    useEffect(() => {
        if (IsExpiredReducerToken) {
            axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + RefreshToken,
                }
            })
                .then((doc) => {
                    const token = doc.data.token;
                    const userDetals = doc.data.data
                    cookie.set("bearer", { token: token, refreshToken: RefreshToken, userDetals: userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                    dispatch(setUser({ token: token, refreshToken: RefreshToken, userDetals: userDetals }))
                })
                .catch((err) => {
                    console.log("err refreshPage : ", err);
                })
        }
    }, [IsExpiredReducerToken, RefreshToken, TokenReducer, dispatch, cookie])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (<>
        <nav style={{ minHeight: "12vh", width: "100%" }}>
            <div style={isMobile ? { position: "fixed", zIndex: 62, width: "100%", marginBottom: 500 } : {}}>
                <div className="NavHedar" style={{ alignItems: "none", flexDirection: "column", width: "100%" }}>
                    <div style={{ padding: isMobile ? "0" : "0 40px", width: "100%", maxWidth: "1665px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", backgroundColor: "#7f0000", padding: isMobile ? 0 : "0 40px" }}>
                            {
                                isMobile ?
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            onClick={toggleMenu}
                                            style={{
                                                background: 'none',
                                                backgroundColor: menuVisible ? "#dacda3" : ThemeColor.bgColor(0.2),
                                                border: 'none',
                                                fontSize: '20px',
                                                color: menuVisible ? ThemeColor.bgColor(1) : "#dacda3",
                                                cursor: 'pointer',
                                                padding: "2px 10px"
                                            }}
                                        >
                                            <FaBars />
                                        </Button>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button style={{ backgroundColor: "white", border: "none", padding: 0 }} onClick={() => nav(`/client/homePage`)} >
                                                <img style={{ maxHeight: 40, padding: 5, backgroundColor: ThemeColor.bgColor(1) }} src={img2} />
                                            </Button>
                                        </div>
                                        <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", alignItems: "center", backgroundColor: ThemeColor.bgColor(1), color: "#dacda3" }} onClick={() => setShowShoppingCart(true)}>
                                            <div style={{ backgroundColor: "#dacda3", minWidth: "24px", borderRadius: "50%", color: ThemeColor.bgColor(1) }}>{quantitys}</div>
                                            <MdOutlineShoppingCart className='IconHedar' size={"30px"} />
                                        </Button>
                                    </div>
                                    :
                                    <div>
                                        <div className='MenuNavLink' style={{ display: "flex", gap: "20px", flexDirection: "row-reverse" }}>
                                            {dataCategotys?.slice(0, 10).map((catogory, index) => (
                                                <NavLink className={"NavLinkHedar"} to={`/client/market/category/${catogory.name.en}`} key={index} ><Langu {...catogory.name} /></NavLink>
                                            ))}
                                            <NavLink className={"NavLinkHedar"} to="/client/homePage">{t("Home.home")}</NavLink>
                                        </div>
                                    </div>
                            }
                            {menuVisible && (
                                <div style={{
                                    position: 'absolute',
                                    top: '60px',
                                    right: '0',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    padding: '10px',
                                    zIndex: 61
                                }}>
                                    <NavLink className={"NavLinkHedar"} to="/client/homePage" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        gap: 10,
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >
                                        <img draggable={false} style={{ width: 20, height: 20 }} src={img3} />
                                        <p style={{ margin: 0 }}>{t("Home.home")}</p>
                                    </NavLink>
                                    {dataCategotys?.map((catogory, index) => (
                                        <NavLink
                                            key={index}
                                            to={`/client/market/category/${catogory.name.en}`}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'black',
                                                fontWeight: '700',
                                                transition: 'color 0.3s',
                                                display: "flex",
                                                gap: 10,
                                            }}
                                            onClick={toggleMenu}
                                            onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                            onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                        >
                                            <img draggable={false} style={{ width: 20, height: 20 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${catogory.file ? catogory.file?.[2] : ""}`} />
                                            <Langu {...catogory.name} />
                                        </NavLink>
                                    ))}

                                    {/* إضافة المزيد من الروابط حسب الحاجة */}
                                </div>
                            )}
                            <div style={{ display: "flex", gap: "5px", alignItems: "center", marginTop: 0, justifyContent: "space-between", minHeight: 40 }}>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px", alignItems: "center", backgroundColor: "#7f0000", color: "#dfcaa3" }} onClick={() => { nav(`/client/market/Favorite/${userData.user?.userDetals?._id}`) }}>
                                        <div style={{ backgroundColor: "#dacda3", minWidth: "24px", borderRadius: "50%", color: ThemeColor.bgColor(1), }}>{userData.user.userDetals?.favorite?.length}</div>
                                        <GrFavorite className='IconHedar' size={"25px"} />
                                    </Button>
                                </div>
                                <AccuntDropdownClient />
                                <I18nClient />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "1640px", width: "100%", padding: isMobile ? "0 10px 20px 10px" : "0 40px 20px 40px", alignItems: "center" }}>
                            {
                                isMobile ?
                                    <div style={{ display: "flex", justifyContent: "center", maxWidth: "1640px", width: "100%", alignItems: "center", marginTop: 5 }}>
                                        <div style={{ maxWidth: "100%", display: "flex", flexDirection: "column", width: "100%" }}>
                                            <div style={{ width: "100%", display: "flex" }}>
                                                <div style={{ width: "100%" }}>
                                                    <SearchBar />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "1640px", width: "100%", padding: "0 40px 20px 40px", alignItems: "center" }}>
                                        <div>
                                            <Button style={{ backgroundColor: "white", border: "none" }} onClick={() => nav(`/client/homePage`)} >
                                                <img src={img1} />
                                            </Button>
                                        </div>
                                        <div style={{ width: "40%" }}>
                                            <SearchBar />
                                        </div>
                                        <div style={{ width: 200, display: "flex", justifyContent: "flex-end" }}>
                                            <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                                <div style={{ backgroundColor: ThemeColor.bgColor(1), minWidth: "24px", borderRadius: "50%", color: "white" }}>{quantitys}</div>
                                                <MdOutlineShoppingCart className='IconHedar' size={"50px"} />
                                            </Button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarClient;