import { useLocation, useNavigate, useParams } from 'react-router';
import '../../homeAdmin/Home.admin.css'
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import ModelXLAdmin from '../Modales/ModaleXL.admin';
import Langu from '../../../components/languge/languge';
import { Form } from 'react-bootstrap';
import AdminYouMayAlsoLike from '../../components/CardsProducts/AdminYouMayAlsoLike';
import { useTranslation } from 'react-i18next';
import { Brand, Category, Product } from '../../../Interfaces';
import { ChatContext } from '../../../components/context/ChatContext';
import CardProducts from '../../../guest/component/CardProductsGuest';
import AdminCardPeoduct from '../../components/CardsProducts/AdminCardProduct';

const CategoryAdmin: React.FC = () => {
    const param = useParams()
    const type = param.type
    const value = param.value
    const categoryName = param.name
    const context = useContext(ChatContext)
    const { isMobile, baseCurrency } = context!
    const { t } = useTranslation()
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [checkBrand, setCheckBrand] = useState<string[]>([])
    const [checkStatus, setCheckStatus] = useState<string[]>([])
    const [checkUsage, setCheckUsage] = useState<string[]>([])
    const [price, setPrice] = useState<string>("All")
    const [checkedCategorys, setCheckedCategorys] = useState<Record<string, string[]>>({})
    const [dataCategorys, setDataCategorys] = useState<Category[]>();

    const [showModalXL, setShowModalXL] = useState<any>({
        setShow: false,
        data: {}
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataBrands(doc.data))
            .catch((err) => console.log(err))
    }, [categoryName])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [categoryName])
    const dataCategory: Category | undefined = dataCategorys?.find((i) => i.name.en === categoryName)

    useEffect(() => {
        axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutSearchProductsCategory/${categoryName}`, {
            search: param.product, price: (Number(price) * baseCurrency), brand: checkBrand, classification: checkUsage, statusCategory: checkStatus, category: categoryName, categorys: Object.entries(checkedCategorys).map(([type, value]) => ({ type, value }))
        })
            .then((doc) => setDataProducts(doc.data.movies))
    }, [param, price, checkBrand, checkStatus, checkUsage, checkedCategorys])

    useEffect(() => {
        if (type === "brand") {
            setCheckBrand([value!])
        }
        else if (type === "classification") {
            setCheckUsage([value!])
        }
        else if (type === "statusCategory") {
            setCheckStatus([value!])
        }
        else if (type === "categorys" && value) {
            const [mainCategory, subCategory] = value?.split(',');
            setCheckedCategorys({ [mainCategory]: [subCategory] });
        }
    }, [type, value])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(e.target.value);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckBrand([...checkBrand!, value]);
        } else {
            setCheckBrand(checkBrand!.filter((brand) => brand !== value));
        }
    };


    const handleCheckboxChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckStatus([...checkStatus!, value]);
        } else {
            setCheckStatus(checkStatus!.filter((brand) => brand !== value));
        }
    };

    const handleCheckboxChangeUsage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckUsage([...checkUsage!, value]);
        } else {
            setCheckUsage(checkUsage!.filter((brand) => brand !== value));
        }
    };

    const handelCheckedCategorys = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked, name } = e.target;
        setCheckedCategorys((prev) => {
            const updated = { ...prev };

            // إذا كان التصنيف غير موجود، قم بإنشاء مصفوفة جديدة
            if (!updated[name]) {
                updated[name] = [];
            }

            // إذا كان الخيار محددًا، أضفه إلى المصفوفة
            if (checked) {
                updated[name] = [...(updated[name] as string[]), value];
            } else {
                // إذا لم يكن محددًا، قم بإزالته من المصفوفة
                updated[name] = (updated[name] as string[]).filter((item) => item !== value);
            }

            // حذف المفتاح إذا كانت المصفوفة فارغة
            if ((updated[name] as string[]).length === 0) {
                delete updated[name];
            }

            return updated;
        });
    };

    return (<>
        <div>
            <div style={{
                // backgroundImage: `url(${process.env.REACT_APP_URL_API}/API/files/Categorys/${category})`,
                backgroundColor: "gray",
                minHeight: "20vh", backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
                <div className="nameOffer" style={{ top: isMobile ? "calc(10vh + 100px)" : "calc(10vh + 250px)" }}>{categoryName}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {
                    !isMobile &&
                    <div style={{ width: "20%", height: "100%", maxWidth: "250px", border: "1px solid #dddddd", margin: "50px 20px 50px 0", padding: "10px" }}>
                        <div style={{ minHeight: '30vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Home.You may also like")}</div>
                            <div>
                                <AdminYouMayAlsoLike />
                            </div>
                        </div>
                        <div style={{ minHeight: '10vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.price")}</div>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                                    <div className="radio-container" style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px", display: "flex", gap: "30px", flexDirection: "column" }}>
                                        {["All", "0-49", "50-99", "100-299"].map((pricee, index) => (
                                            <label key={index} className="radio-label" htmlFor={`${pricee}`} style={{ display: "flex", gap: "5px" }}>
                                                <input
                                                    type="radio"
                                                    id={`${pricee}`}
                                                    name="priceFiltar"
                                                    value={`${pricee}`}
                                                    checked={price === `${pricee}`}
                                                    onChange={handleChange}
                                                />
                                                {`${pricee}`}
                                                <span className="radio-custom"></span>
                                            </label>
                                        ))}
                                        <label className="radio-label" htmlFor="300" style={{ display: "flex", gap: "5px" }}>
                                            <input
                                                type="radio"
                                                id="300"
                                                name="priceFiltar"
                                                value="300"
                                                checked={price === "300"}
                                                onChange={handleChange}
                                            />
                                            {t("Market.more than 300")}
                                            <span className="radio-custom"></span>
                                        </label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh', borderBottom: "1px solid #dddddd" }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.brand")}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        dataBrands?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand.name.en}
                                                label={<Langu {...brand.name} />}
                                                value={brand.name.en}
                                                onChange={handleCheckboxChange}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh', borderBottom: "1px solid #dddddd" }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t(`Hedar.Notifications.status`)}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        ["newStatus", "asNew", "used"]?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand}
                                                label={t(`Market.${brand}`)}
                                                checked={checkStatus.includes(brand)}
                                                value={brand}
                                                onChange={handleCheckboxChangeStatus}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t(`Hedar.Notifications.Usage`)}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        ["gaming", "desktop"]?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand}
                                                label={t(`Market.${brand}`)}
                                                value={brand}
                                                checked={checkUsage.includes(brand)}
                                                onChange={handleCheckboxChangeUsage}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                        {dataCategory?.Categorys?.map((Category, index) => (
                            <div style={{ minHeight: '12vh', borderTop: "1px solid #dddddd" }} key={index}>
                                <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>
                                    <Langu {...Category?.name} />
                                </div>
                                <div>
                                    <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                        {
                                            Category?.Categories?.map((brand, index) => (
                                                <Form.Check
                                                    key={index}
                                                    type="checkbox"
                                                    id={brand.en}
                                                    label={<Langu {...brand} />}
                                                    value={brand.en}
                                                    name={Category.name.en}
                                                    checked={(checkedCategorys[Category.name.en] || []).includes(brand.en)}
                                                    onChange={handelCheckedCategorys}
                                                />

                                            ))
                                        }
                                    </Form>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                {
                    dataProducts?.length! > 0 ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%" }}>
                            <div style={{ display: "flex", gap: isMobile ? 5 : "20px", marginTop: "30px", marginBottom: isMobile ? 30 : 0, padding: isMobile ? "0 5px" : "0 40px", flexWrap: "wrap", justifyContent: "center", maxWidth: "1440px", width: "100%", alignContent: "start" }}>
                                {dataProducts?.map((Product, index) => (
                                    <AdminCardPeoduct dragging={false} Product={Product} key={index} />
                                ))}
                            </div>
                        </div>
                        :
                        <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                            {t("Market.There are no products with these specifications.")}
                        </div>
                }
            </div>
            {
                showModalXL.setShow &&
                <ModelXLAdmin show={showModalXL.setShow} setShowModalXL={setShowModalXL} data={showModalXL.data} />
            }
        </div>
    </>)
}

export default CategoryAdmin;