import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { BiSupport } from "react-icons/bi";
import "./SupportIconClient.css"
import { useTranslation } from 'react-i18next';
import { SiWhatsapp } from "react-icons/si";
import { IoIosChatbubbles } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useNavigate } from 'react-router';
import { ChatContext } from '../../../components/context/ChatContext';
import { Modal } from 'react-bootstrap';
import { ThemeColor } from '../../../theme';

const FloatingButtonClient: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const chatContext = useContext(ChatContext);

    const { notificationDB, isMobile } = chatContext!

    const [isOpen, setIsOpen] = useState(false);
    const [showWhatsapp, setShowWhatsapp] = useState<boolean>(false)
    const [showTelephone, setShowTelephone] = useState<boolean>(false)
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="floating-container" style={{ bottom: isMobile ? 20 : 30, right: isMobile ? 20 : 35 }}>
            {notificationDB?.length > 0 && <div style={{ position: "absolute", width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "red", color: "white", fontSize: "15px", bottom: "40px", left: "-10px", zIndex: 3, textAlign: "center" }}>  <span>{notificationDB?.length!}</span></div>}
            <div className={`icon-container ${isOpen ? 'open' : ''}`}>
                <Button onClick={() => setShowWhatsapp(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '8px' }}>
                    <SiWhatsapp color='#00a884' />
                </Button>
                <Button onClick={() => nav("/client/Chat")} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '6px', position: "relative" }}>
                    {notificationDB?.length > 0 && <div style={{ position: "absolute", width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "red", color: "white", fontSize: "15px", top: "-10px", left: "-10px" }}>  <span>{notificationDB?.length!}</span></div>}
                    <IoIosChatbubbles color={ThemeColor.bgColor(1)} />
                </Button>
                <Button onClick={() => setShowTelephone(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '10px' }}>
                    <BsFillTelephoneFill color='#008069' />
                </Button>
            </div>

            <Button onClick={toggleMenu} bsPrefix='BtnBNB' style={{ position: 'fixed', bottom: isMobile ? 20 : '30px', right: isMobile ? 20 : '30px', padding: isMobile ? "0 5px" : "0 10px", borderRadius: "50%", height: isMobile ? 40 : "50px" }}>
                <BiSupport size={"30px"} />
            </Button>
            <Modal show={showWhatsapp} onHide={() => setShowWhatsapp(false)}>
                <Modal.Header className="CloseBtn" closeButton>
                    <Modal.Title>
                        Whatsapp
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
                    <a style={{ textDecoration: "none", fontSize: 30, color: "rgb(0, 168, 132)", alignItems: "center", display: "flex" }} href='https://wa.me/message/SOFQSHYZ7M35D1?src=qr'>  +90 551 189 12 53 {"  "} </a>
                </Modal.Body>
                <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                    <Button variant="secondary" onClick={() => setShowWhatsapp(false)}>{t("Hedar.Close")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showTelephone} onHide={() => setShowTelephone(false)}>
                <Modal.Header className="CloseBtn" closeButton>
                    <Modal.Title>
                        {t("Home.phone")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", justifyContent: "center", fontSize: 30 }}>
                    +90 551 189 12 53
                </Modal.Body>
                <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                    <Button variant="secondary" onClick={() => setShowTelephone(false)}>{t("Hedar.Close")}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default FloatingButtonClient;