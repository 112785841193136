import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CartItemCompletePage from "../../../components/cartItem/CartItemCompletePage";
import { useTranslation } from "react-i18next";
import BasicExample from "./Accordion";
import { ChatContext } from "../../../components/context/ChatContext";
import { useContext, useEffect, useState } from "react";
import Langu from "../../../components/languge/languge";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { cleenItems } from "../../../redux/counterSlice";
import { useNavigate } from "react-router";
import { UsersProps } from "../../../Interfaces";




const CompleteOrder: React.FC = () => {
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch()
    const nav = useNavigate()
    const { t } = useTranslation()
    const context = useContext(ChatContext)
    const { isMobile, baseCurrency } = context!

    const [pickup, setPickup] = useState<string>()
    const [confirmBtn, setConfirmBtn] = useState<any>(t("Market.PayPage.confirm"))
    const [disabled, setDisabled] = useState<boolean>(false)
    const [errPickup, setErrPickup] = useState<string>()
    const [dataUserId, setDataUserId] = useState<UsersProps>()
    const [country, setCountry] = useState<string | undefined>(dataUserId ? dataUserId?.address?.country : "")
    const [neighborhood, setNeighborhood] = useState<string | undefined>(dataUserId ? dataUserId?.address?.neighborhood : "")
    const [street, setStreet] = useState<string | undefined>(dataUserId ? dataUserId?.address?.street : "")
    const [buildingNumber, setBuildingNumber] = useState<string | number | undefined>(dataUserId ? dataUserId?.address?.buildingNumber : "")
    const [floor, setFloor] = useState<number | undefined>(dataUserId ? dataUserId?.address?.floor : 0)
    const [apartmentNo, setApartmentNo] = useState<number | undefined>(dataUserId ? dataUserId?.address?.apartmentNo : 0)
    const [disabled2, setDisabled2] = useState<boolean>(false)
    const [statusBtn, setStatusBtn] = useState<any>(t("Market.PayPage.confirm"))

    useEffect(() => {
        setCountry(dataUserId ? dataUserId?.address?.country : "")
        setNeighborhood(dataUserId ? dataUserId?.address?.neighborhood : "")
        setStreet(dataUserId ? dataUserId?.address?.street : "")
        setBuildingNumber(dataUserId ? dataUserId?.address?.buildingNumber : "")
        setFloor(dataUserId ? dataUserId?.address?.floor : 0)
        setApartmentNo(dataUserId ? dataUserId?.address?.apartmentNo : 0)
    }, [dataUserId])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData?.user?.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUserId(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const subTotal: number = CarentItem.cartItems.reduce((total, cartitem) => {
        return total + ((cartitem.data.newPrice * baseCurrency) || (cartitem.data.price * baseCurrency) || 0) * cartitem.quantity
    }, 0)

    const Delivery = 0

    const Total: number = subTotal + Delivery

    const dataItems = CarentItem?.cartItems.map((item) => {
        return {
            itemId: item.data._id,
            product: item.data.product,
            price: item.data.price,
            newPrice: item.data.newPrice,
            offers: item.data.offers,
            quantity: item.quantity,
            color: item.color
        }
    })
    // const dataPutAddress = { address: { country, neighborhood, street, buildingNumber, floor, apartmentNo } }

    const dataPutAddress = { address: { country, neighborhood, street, buildingNumber, floor, apartmentNo } }

    const dataPostOrder = {
        userId: userData?.user?.userDetals?._id!,
        phone: userData?.user?.userDetals?.phone!,
        totalPrice: Total,
        paymentMethod: "cash",
        dataItems,
        userName: userData.user.userDetals?.userName!,
        email: userData.user.userDetals?.email
        // address: dataPutAddress,
    }
    const putAddressUser = async () => {
        setDisabled2(true)
        setStatusBtn(<LoadingBtn />)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutUserId/${userData?.user?.userDetals?._id}`,
            dataPutAddress,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(async () => {
                await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, dataPostOrder, {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    }
                })
                    .then((doc) => {
                        dispatch(cleenItems())
                        nav(`/client/market/invoice/${doc.data._id}`)
                    })
                    .catch(err => console.log(err))
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtn(t("Market.PayPage.confirm"))
                setDisabled2(false)
            })
    }

    const CreateOrder = async () => {
        if (pickup === "from store") {
            setDisabled(true)
            setConfirmBtn(<LoadingBtn />)
            await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, dataPostOrder, {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            })
                .then((doc) => {
                    dispatch(cleenItems())
                    nav(`/client/market/invoice/${doc.data._id}`)
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setConfirmBtn(t("Market.PayPage.confirm"))
                    setDisabled(false)
                })
        }
        else if (pickup === "delivery") {

        }
        else {
            setErrPickup("اختر استلام")
        }
    }

    return (<>
        {
            isMobile ?
                <div>
                    {CarentItem.cartItems.map((product, index) => (
                        <div key={index} style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #dddddd", padding: 10 }}>
                            <img style={{ height: "50px", width: "60px", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${product?.data?.file?.[0]}`} alt={product.data.product?.en} />
                            <div>
                                <Langu {...product.data.product} />
                            </div>
                            <div>
                                x{product.quantity}
                            </div>
                            {
                                product.data.newPrice ?
                                    <div>
                                        <div style={{ textDecoration: "line-through", color: "gray" }}>
                                            {product.data?.price! * product.quantity} ₺
                                        </div>
                                        <div style={{ color: "red" }}>
                                            {product.data?.newPrice * product.quantity} ₺
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {product.data?.price! * product.quantity} ₺
                                    </div>
                            }
                        </div>
                    ))}
                    <div style={{ padding: "30px 10px 10px 10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.Subtotal")}
                            </div>
                            <div>
                                {Math.round(subTotal)} ₺
                            </div>
                        </div>
                        <div style={{ fontSize: "16px", fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.shipping")}
                            </div>
                            <div>
                                {Delivery} ₺
                            </div>
                        </div>
                        <div style={{ fontSize: "186x", fontWeight: "700", display: 'flex', justifyContent: "space-between" }}>
                            <div style={{ fontWeight: "700" }}>
                                {t("Market.PayPage.Total")}
                            </div>
                            <div>
                                {Math.round(Total)} ₺
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 15, borderTop: "1px solid #dddddd" }}>
                        <div style={{ fontSize: "18px", fontWeight: 600, padding: 10 }}>
                            {t('Market.pickup')}
                        </div>
                        <div style={{ display: "flex", gap: 15, border: "1px solid #dddddd", padding: 20, borderRadius: 10, width: "50%" }}
                            onClick={() => { setPickup("from store"); setErrPickup('') }}>
                            <Form.Check
                                id="from store"
                                value={'from store'}
                                name="pickup"
                                type="radio"
                                checked={pickup === "from store"}
                                style={{ fontSize: 20, fontWeight: 600 }}
                            />
                            <label htmlFor={"from store"} style={{ fontSize: 18, fontWeight: 600 }} >{t(`Market.from store`)}</label>
                        </div>
                        <div style={{ display: "flex", gap: 15, border: "1px solid #dddddd", padding: 20, borderRadius: 10, width: "50%", marginBottom: 10 }}
                            onClick={() => { setPickup("delivery"); setErrPickup('') }}>
                            <Form.Check
                                id="delivery"
                                value={'delivery'}
                                name="pickup"
                                type="radio"
                                checked={pickup === "delivery"}
                                style={{ fontSize: 20, fontWeight: 600 }}
                            />
                            <label htmlFor={"delivery"} style={{ fontSize: 18, fontWeight: 600 }}>{t(`Market.delivery`)}</label>
                        </div>
                    </div>
                    {errPickup &&
                        <div style={{ color: "red", padding: 10, backgroundColor: "#ffe4e4", textAlign: "center", fontSize: 18, fontWeight: 600 }}>
                            {errPickup}
                        </div>
                    }
                    {pickup === "from store" &&
                        <div style={{ width: "100%", textAlign: "center", borderTop: "1px solid #dddddd", padding: 10 }}>
                            <Button bsPrefix="BtnBNB" onClick={CreateOrder} disabled={disabled}>{confirmBtn}</Button>
                        </div>
                    }
                    {pickup === "delivery" &&
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: 15, borderTop: "1px solid #dddddd" }}>
                            <div style={{ marginBottom: "20px", fontSize: 20, fontWeight: 600 }}>
                                {t("Market.PayPage.addres")}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRadius: "10px", justifyContent: "center" }}>
                                <div style={{ width: "100%", maxWidth: "1000px", textAlign: "center" }}>
                                    <Form.Label>{t("Market.PayPage.Country")} :</Form.Label>
                                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                        <Form.Control type="text" placeholder="" style={{ textAlign: "center", width: "80%" }} autoFocus onChange={(e) => setCountry(e.target.value)} defaultValue={dataUserId?.address?.country} />
                                    </Form.Group>
                                </div>
                                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <Form.Label>{t("Market.PayPage.Neighborhood")} :</Form.Label>
                                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                        <Form.Control type="text" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setNeighborhood(e.target.value)} defaultValue={dataUserId?.address?.neighborhood} />
                                    </Form.Group>
                                </div>

                                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <Form.Label>{t("Market.PayPage.Street")} :</Form.Label>
                                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                        <Form.Control type="text" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setStreet(e.target.value)} defaultValue={dataUserId?.address?.street} />
                                    </Form.Group>
                                </div>
                                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <Form.Label> {t("Market.PayPage.Building number")}:</Form.Label>
                                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                        <Form.Control type="text" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setBuildingNumber(e.target.value)} defaultValue={dataUserId?.address?.buildingNumber} />
                                    </Form.Group>
                                </div>
                                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <Form.Label>{t('Market.PayPage.floor')} :</Form.Label>
                                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                        <Form.Control type="number" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setFloor(parseInt(e.target.value))} defaultValue={dataUserId?.address?.floor} />
                                    </Form.Group>
                                </div>
                                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <Form.Label> {t("Market.PayPage.Apartment No")} :</Form.Label>
                                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "flex", textAlign: "center", width: "100%", justifyContent: "center" }}>
                                        <Form.Control type="number" placeholder="" style={{ textAlign: "center", width: "80%" }} onChange={(e) => setApartmentNo(parseInt(e.target.value))} defaultValue={dataUserId?.address?.apartmentNo} />
                                    </Form.Group>
                                </div>
                                <div style={{ padding: 15 }}>
                                    <Button bsPrefix="BtnBNB" disabled={disabled2} onClick={putAddressUser}>{statusBtn}</Button>
                                </div>
                            </div>

                        </div>}

                </div>
                :
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        {BasicExample()}
                    </div>
                    <div style={{ width: "50%", backgroundColor: "rgb(241 241 241)", minHeight: "100vh", padding: "0 20px" }}>
                        <div style={{ position: "sticky", top: "75px", marginTop: "50px", width: "500px" }}>
                            <div style={{}}>
                                {CarentItem?.cartItems.map((item: any, index) => { return item ? <CartItemCompletePage key={index} data={item} /> : null }
                                )}
                            </div>
                            <div>
                                <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                                    <div>
                                        {t("Market.PayPage.Subtotal")}
                                    </div>
                                    <div>
                                        {Math.round(subTotal)} ₺
                                    </div>
                                </div>
                                <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                                    <div>
                                        {t("Market.PayPage.shipping")}
                                    </div>
                                    <div>
                                        {Delivery} ₺
                                    </div>
                                </div>
                                <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                                    <div>
                                        {t("Market.PayPage.Total")}
                                    </div>
                                    <div>
                                        {Math.round(Total)} ₺
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
    </>)
}

export default CompleteOrder;