interface Notification {
    chatId?: string;
    createdAt?: Date;
    senderId?: string;
    text?: string;
    updatedAt?: Date;
    isRead: boolean;
    _id?: string
}

// interface UnreadNotificationProps {
//     notification: {
//         date: Date;
//         isRead: boolean;
//         senderId: string;
//     }[]; // يجب أن تكون مصفوفة من الإشعارات
// }

interface UnreadNotificationProps {
    notificationDB: {
        chatId?: string;
        createdAt?: Date;
        senderId?: string;
        isRead: boolean;
        text?: string;
        updatedAt?: Date;
        _id?: string
    }[]; // يجب أن تكون مصفوفة من الإشعارات
}

export const UnreadNotification = ({ notificationDB }: UnreadNotificationProps): Notification[] => {
    // تصفية الإشعارات غير المقروءة


    return notificationDB.filter((notificationDB: { isRead: boolean; }) => notificationDB.isRead === false);
};

