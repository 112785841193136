import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { BiSupport } from "react-icons/bi";
import "./SupportIcon.css"
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SiWhatsapp } from "react-icons/si";
import { IoIosChatbubbles } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ThemeColor } from '../../../theme';
import { ChatContext } from '../../../components/context/ChatContext';
import { Modal } from 'react-bootstrap';

const FloatingButton: React.FC = () => {
  const { t } = useTranslation()
  const chatContext = useContext(ChatContext)
  const { isMobile } = chatContext!
  const [isOpen, setIsOpen] = useState(false);
  const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
  const [showWhatsapp, setShowWhatsapp] = useState<boolean>(false)
  const [showTelephone, setShowTelephone] = useState<boolean>(false)
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="floating-container" style={{ bottom: isMobile ? 20 : 30, right: isMobile ? 20 : 35 }}>
      <div className={`icon-container ${isOpen ? 'open' : ''}`}>
        <Button onClick={() => setShowWhatsapp(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '8px' }}>
          <SiWhatsapp color='#00a884' />
        </Button>
        <Button onClick={() => setShowAlirtMsg(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '6px' }}>
          <IoIosChatbubbles color={ThemeColor.bgColor(1)} />
        </Button>
        <Button onClick={() => setShowTelephone(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '10px' }}>
          <BsFillTelephoneFill color='#008069' />
        </Button>
      </div>

      <Button onClick={toggleMenu} bsPrefix='BtnBNB' style={{ position: 'fixed', bottom: isMobile ? 20 : '30px', right: isMobile ? 20 : '30px', padding: isMobile ? "0 5px" : "0 10px", borderRadius: "50%", height: isMobile ? 40 : "50px" }}>
        <BiSupport size={"30px"} />
      </Button>
      <Alert show={showAlirtMsg} variant={"danger"} style={{ position: "fixed", top: '80px', left: "30px", minWidth: "20%", zIndex: "10" }} onClose={() => setShowAlirtMsg(false)} dismissible>
        <Alert.Heading style={{ textAlign: "start" }}>
          {t("Market.Error message")}
        </Alert.Heading>
        <p>
          {t("Market.You are not registered, please log in to continue")}
        </p>
      </Alert>
      <Modal show={showWhatsapp} onHide={() => setShowWhatsapp(false)}>
        <Modal.Header className="CloseBtn" closeButton>
          <Modal.Title>
            Whatsapp
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <a style={{ textDecoration: "none", fontSize: 30, color: "rgb(0, 168, 132)", alignItems: "center", display: "flex" }} href='https://wa.me/message/SOFQSHYZ7M35D1?src=qr'>  +90 551 189 12 53 {"  "} </a>
        </Modal.Body>
        <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
          <Button variant="secondary" onClick={() => setShowWhatsapp(false)}>{t("Hedar.Close")}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showTelephone} onHide={() => setShowTelephone(false)}>
        <Modal.Header className="CloseBtn" closeButton>
          <Modal.Title>
            {t("Home.phone")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center", fontSize: 30 }}>
          +90 551 189 12 53
        </Modal.Body>
        <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
          <Button variant="secondary" onClick={() => setShowTelephone(false)}>{t("Hedar.Close")}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}


export default FloatingButton;