import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import PaymentMethods from './PaymentMethods';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import axios from 'axios';
import { cleenItems } from '../../../redux/counterSlice';
import { useNavigate } from 'react-router';
import { LoadingBtn } from '../../../components/laoding/laoding';
import { ChatContext } from '../../../components/context/ChatContext';

function BasicExample() {
    const { t, i18n } = useTranslation()
    const nav = useNavigate()
    const dispatch = useDispatch()
    const accordionRef = useRef<HTMLDivElement>(null);
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData);
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!
    const [status, setStatus] = useState<boolean>(false)
    const [activeKey, setActiveKey] = useState<string | null>("0");
    const [aaaa, setaaaa] = useState<string>()
    const [confirmBtn, setConfirmBtn] = useState<any>(t("Market.PayPage.confirm"))
    const [disabled, setDisabled] = useState<boolean>(false)
    const subTotal: number = CarentItem?.cartItems.reduce((total, cartitem) => {
        return total + ((cartitem.data.newPrice * baseCurrency) || (cartitem.data.price * baseCurrency) || 0) * cartitem.quantity
    }, 0)
    const delivery = 0
    const totalPrice = Math.round(subTotal + delivery)
    const dataItems = CarentItem?.cartItems.map((item) => {
        return {
            itemId: item.data._id,
            product: item.data.product,
            price: item.data.price,
            newPrice: item.data.newPrice,
            offers: item.data.offers,
            quantity: item.quantity,
            color: item.color
        }
    })
    // const dataPutAddress = { address: { country, neighborhood, street, buildingNumber, floor, apartmentNo } }


    const dataPostOrder = {
        userId: userData?.user?.userDetals?._id!,
        phone: userData?.user?.userDetals?.phone!,
        totalPrice,
        paymentMethod: "cash",
        dataItems,
        userName: userData.user.userDetals?.userName!,
        email: userData.user.userDetals?.email
        // address: dataPutAddress,
    }

    useEffect(() => {
        const isRtl = i18n.language === 'ar' || i18n.language === 'he';

        if (accordionRef.current) {
            const headers = accordionRef.current.querySelectorAll('.accordion-header');

            headers.forEach((header) => {
                const button = header.querySelector('.accordion-button') as HTMLElement;
                if (button) {
                    button.style.flexDirection = isRtl ? 'row-reverse' : 'row';
                }
            });
        }
    }, [i18n.language])


    const CreateOrder = async () => {
        if (aaaa === "from store") {
            setDisabled(true)
            setConfirmBtn(<LoadingBtn />)
            await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, dataPostOrder, {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            })
                .then((doc) => {
                    dispatch(cleenItems())
                    nav(`/client/market/invoice/${doc.data._id}`)
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setConfirmBtn(t("Market.PayPage.confirm"))
                    setDisabled(false)
                })
        }
        else if (aaaa === "delivery") {
            setStatus(true)
            setActiveKey("1");
        }
    }

    const handleSelect = (key: AccordionEventKey) => {
        if (typeof key === 'string') {
            setActiveKey(key);
        }
    };

    return (
        <Accordion activeKey={activeKey} defaultActiveKey="0" ref={accordionRef} onSelect={handleSelect}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>{t('Market.pickup')} </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <div>
                            {t('Market.pickup')} :
                        </div>
                        <div style={{ display: "flex", gap: 20, borderBottom: "1px solid #dddddd", padding: 20 }}>
                            {["from store", "delivery"].map((pricee, index) => (
                                <label key={index} className="radio-label" htmlFor={`${pricee}`} style={{ display: "flex", gap: "5px" }}>
                                    <input
                                        type="radio"
                                        id={`${pricee}`}
                                        name="priceFiltar"
                                        value={`${pricee}`}
                                        checked={aaaa === `${pricee}`}
                                        onChange={() => setaaaa(pricee)}
                                    />
                                    {t(`Market.${pricee}`)}
                                    <span className="radio-custom"></span>
                                </label>
                            ))}
                        </div>
                        <div style={{ marginTop: 20, width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button bsPrefix="BtnBNB" onClick={CreateOrder} disabled={disabled}>{confirmBtn}</Button>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" >
                <Accordion.Header style={{ pointerEvents: status ? 'auto' : 'none', opacity: status ? 1 : 0.5 }}>{t("Market.PayPage.addres")}</Accordion.Header>
                <Accordion.Body>
                    <PaymentMethods />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default BasicExample;