import { Button } from 'react-bootstrap';
import './HedarGuest.css'
import LoginModal from './LoginModal';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaRegUser } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GrFavorite } from "react-icons/gr";
import ShoppingCart from './ShoppingCart';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import I18nGuest from './I18n.guest';
import SearchBar from '../../components/searchBar/SearchBar';
import { ThemeColor } from '../../theme';
import axios from 'axios';
import Langu from '../../components/languge/languge';
import { Category } from '../../Interfaces';
import img1 from '../../files/HomeGuest/شعار أبو أحمد معدل مقصوص.jpg'
import img2 from '../../files/HomeGuest/شعار خلفية حمرة معدل.jpg'
import img3 from '../../files/HomeGuest/IconHome2.png'
import { ChatContext } from '../../components/context/ChatContext';

const HedarGuest: React.FC = () => {
    const { t } = useTranslation()
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!

    const [showModalLogin, setShowModalLogin] = useState<boolean>(false)
    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)
    const [dataCategotys, setDataCategorys] = useState<Category[]>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [])
    const [menuVisible, setMenuVisible] = useState<boolean>(false);

    // دالة للتبديل بين ظهور وإخفاء القائمة
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (<>
        <nav style={{ minHeight: "12vh", width: "100%" }}>
            <div style={isMobile ? { position: "fixed", zIndex: 62, width: "100%", marginBottom: 500 } : {}}>
                <div className="NavHedar" style={{ alignItems: "none", flexDirection: "column", width: "100%" }}>
                    <div style={{ padding: isMobile ? "0 " : "0 40px", width: "100%", maxWidth: "1665px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", backgroundColor: "#7f0000", padding: isMobile ? 0 : "0 40px" }}>
                            {isMobile ?
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Button
                                        onClick={toggleMenu}
                                        style={{
                                            background: 'none',
                                            backgroundColor: menuVisible ? "#dacda3" : ThemeColor.bgColor(0.2),
                                            border: 'none',
                                            fontSize: '20px',
                                            color: menuVisible ? ThemeColor.bgColor(1) : "#dacda3",
                                            cursor: 'pointer',
                                            padding: "2px 10px"
                                        }}
                                    >
                                        <FaBars />
                                    </Button>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button style={{ backgroundColor: "white", border: "none", padding: 0 }} onClick={() => nav(`/guest/homePage`)} >
                                            <img style={{ maxHeight: 40, padding: 5, backgroundColor: ThemeColor.bgColor(1) }} src={img2} />
                                        </Button>
                                    </div>
                                    <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", alignItems: "center", backgroundColor: ThemeColor.bgColor(1), color: "#dacda3" }} onClick={() => setShowShoppingCart(true)}>
                                        <div style={{ backgroundColor: "#dacda3", minWidth: "24px", borderRadius: "50%", color: ThemeColor.bgColor(1) }}>{CarentItem.value}</div>
                                        <MdOutlineShoppingCart className='IconHedar' size={"30px"} />
                                    </Button>
                                </div>
                                :
                                <div>
                                    <div className='MenuNavLink' style={{ display: "flex", gap: "20px", flexDirection: "row-reverse" }}>
                                        {dataCategotys?.slice(0, 10).map((catogory, index) => (
                                            <NavLink className={"NavLinkHedar"} to={`/guest/category/${catogory.name.en}`} key={index} ><Langu {...catogory.name} /></NavLink>
                                        ))}
                                        <NavLink className={"NavLinkHedar"} to="/guest/homePage">{t("Home.home")}</NavLink>
                                    </div>
                                </div>
                            }

                            {/* قائمة التنقل التي تظهر عند الضغط على الزر */}
                            {menuVisible && (
                                <div style={{
                                    position: 'absolute',
                                    top: '60px',
                                    right: '0',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    padding: '10px',
                                    zIndex: 61
                                }}>
                                    <NavLink className={"NavLinkHedar"} to="/guest/homePage" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        gap: 10,
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >
                                        <img draggable={false} style={{ width: 20, height: 20 }} src={img3} />
                                        <p style={{ margin: 0 }}>{t("Home.home")}</p>
                                    </NavLink>
                                    {dataCategotys?.map((catogory, index) => (
                                        <NavLink
                                            key={index}
                                            to={`/guest/category/${catogory.name.en}`}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'black',
                                                fontWeight: '700',
                                                transition: 'color 0.3s',
                                                display: "flex",
                                                gap: 10,
                                            }}
                                            onClick={toggleMenu}
                                            onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                            onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                        >
                                            <img draggable={false} style={{ width: 20, height: 20 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${catogory.file ? catogory.file?.[2] : ""}`} />
                                            <Langu {...catogory.name} />
                                        </NavLink>
                                    ))}
                                </div>
                            )}
                            <div style={{ display: "flex", gap: "5px", alignItems: "center", marginTop: 0, justifyContent: "space-between", minHeight: 40 }}>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px", alignItems: "center", backgroundColor: "#7f0000", color: "#dfcaa3" }}>
                                        <div style={{ backgroundColor: "#dacda3", minWidth: "24px", borderRadius: "50%", color: ThemeColor.bgColor(1), }}>0</div>
                                        <GrFavorite className='IconHedar' size={"25px"} />
                                    </Button>
                                </div>
                                <Button bsPrefix='BtnBNBIcon' style={{ backgroundColor: ThemeColor.bgColor(1), color: "#dacda3" }} onClick={() => setShowModalLogin(true)}><FaRegUser className='IconHedar' size={"22px"} /></Button>
                                <I18nGuest />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "1640px", width: "100%", padding: isMobile ? "0 10px 20px 10px" : "0 40px 20px 40px", alignItems: "center" }}>
                        {
                            isMobile ?
                                <div style={{ display: "flex", justifyContent: "center", maxWidth: "1640px", width: "100%", alignItems: "center", marginTop: 5 }}>
                                    <div style={{ maxWidth: "100%", display: "flex", flexDirection: "column", width: "100%" }}>
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <div style={{ width: "100%" }}>
                                                <SearchBar />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "1640px", width: "100%", padding: "0 40px 20px 40px", alignItems: "center" }}>
                                    <div>
                                        <Button style={{ backgroundColor: "white", border: "none" }} onClick={() => nav(`/guest/homePage`)} >
                                            <img src={img1} />
                                        </Button>
                                    </div>
                                    <div style={{ width: "40%" }}>
                                        <SearchBar />
                                    </div>
                                    <div style={{ width: 200, display: "flex", justifyContent: "flex-end" }}>
                                        <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                            <div style={{ backgroundColor: ThemeColor.bgColor(1), minWidth: "24px", borderRadius: "50%", color: "white" }}>{CarentItem.value}</div>
                                            <MdOutlineShoppingCart className='IconHedar' size={"50px"} />
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <LoginModal show={showModalLogin} setShowModalLogin={setShowModalLogin} />
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarGuest;