import { Alert, Button } from "react-bootstrap";
import Langu from "../../../components/languge/languge";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { decrementItems, incrementItems } from "../../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import axios from "axios";
import { setFavorites } from "../../../redux/userSlice";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { Product, UsersProps } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";

interface InfoProductClientProps {
    data: Product
}

const InfoProductClient: React.FC<InfoProductClientProps> = ({ data }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData);
    const [dataProduct, setDataProduct] = useState<UsersProps>()
    const [colorActive, setColorActive] = useState<string>(data?.colors?.[0]!)
    const [quantityItem, setQuantityItem] = useState(0)
    const [favorite, setFavorite] = useState<boolean>(dataProduct?.favorite?.includes(data._id!)!)
    const [showAlertAdd, setShowAlertAdd] = useState<boolean>(false)
    const [showAlertDis, setShowAlertDis] = useState<boolean>(false)
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!
    useEffect(() => {
        setTimeout(() => {
            setColorActive(data?.colors?.[0]!)
            setFavorite(dataProduct?.favorite?.includes(data?._id!)!)
        }, 500)
    }, [dataProduct])

    useEffect(() => {
        const item = CarentItem.cartItems.find((i) => ((i.data._id === data?._id) && (i.color === colorActive)));
        if (item) {
            setQuantityItem(item.quantity);
        } else {
            setQuantityItem(0);
        }
    }, [CarentItem, data, colorActive])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData.user.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => { setDataProduct(doc.data) })
            .catch((err) => console.log(err))
    }, [showAlertAdd, showAlertDis])



    const setShowAlertAd = () => {
        setShowAlertAdd(false)
        setShowAlertDis(true)
    }
    const setShowAlertDi = () => {
        setShowAlertDis(false)
        setShowAlertAdd(true)
    }

    const AddFavorite = async () => {
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddFavorite/${userData.user.userDetals?._id}`, { FavoriteId: data._id, favorite },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(() => {
                favorite === true ? setShowAlertAd() : setShowAlertDi()
                dispatch(setFavorites(data?._id!))
            })
            .catch((err) => console.log(err))
    }

    return (<>
        <div style={{ padding: 10, borderBottom: "1px solid #dddddd" }}>
            <div style={{ margin: '10px 0' }}><Langu {...data.category!} /></div>
            <div style={{ fontWeight: 700, margin: '10px 0' }}><Langu {...data.product!} /></div>
            <div style={{ margin: '10px 0' }}><Langu {...data.brand!} /></div>
            <div style={{ margin: '10px 0' }}><Langu {...data.information!} /></div>
            {data.statusCategory && <div style={{ display: "flex", alignItems: "center" }}><p style={{ fontSize: 20 }}>{t(`Hedar.Notifications.status`)} : </p> <p style={{ padding: "0 5px" }}> {t(`Market.${data.statusCategory}`)} </p> </div>}
            {data.classification && <div style={{ display: "flex", alignItems: "center" }}><p style={{ fontSize: 20 }}>{t(`Hedar.Notifications.Usage`)} : </p> <p style={{ padding: "0 5px" }}> {t(`Market.${data.classification}`)} </p></div>}
            {data.categorys &&
                Object.entries(data.categorys).map(([key, value]) => (
                    <div style={{ display: "flex", alignItems: "center" }} key={key}>
                        <p style={{ fontSize: 20 }}>{key} :</p>  <p style={{ padding: "0 5px" }}> {value} </p>
                    </div>
                ))
            }
            <div style={{ width: "100%", fontSize: "18px", margin: '10px 0' }}>
                {
                    data.newPrice ?
                        <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: '20px' }}>
                            <div>
                                {t('Market.price')} :
                            </div>
                            <div style={{ textDecoration: "line-through", color: "black", fontWeight: 700 }}>
                                {Math.round(Number(data.price) * baseCurrency) + ' ₺'}
                            </div>
                            <div style={{ color: "red", fontWeight: 700 }}>
                                {t('Market.newPrice')} : {Math.round(Number(data.newPrice) * baseCurrency) + ' ₺'}
                            </div>
                        </div>
                        :
                        <div style={{ textAlign: "start", color: "black", fontWeight: 700 }}>
                            {t('Market.price')} : {Math.round(Number(data.price) * baseCurrency) + ' ₺'}
                        </div>
                }
            </div>
            <div style={{ display: "flex" }}>
                {data.colors?.length! > 0 && data.colors?.map((color: string, index) => (
                    <Button
                        key={index}
                        onClick={() => setColorActive(color)}
                        style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive == color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                    </Button>
                ))}
            </div>
            <div style={{ margin: '10px 0' }}>
                {t('Market.number')} :  {data.disable ? 0 : data.number}
            </div>
            <div style={{ margin: '10px 0' }}>
                {t('Market.Sell from him')} :  {data.sold}
            </div>
        </div>
        <div style={{ padding: 10, borderBottom: "1px solid #dddddd", display: "flex", justifyContent: "space-around" }}>
            <div>
                {quantityItem == 0 ?
                    <Button bsPrefix="BtnBNB" disabled={data?.disable} onClick={() => dispatch(incrementItems({ quantity: 1, data, userId: userData.user.userDetals?._id!, color: colorActive }))} >{t("Market.add to cart")}</Button>
                    :
                    <div style={{ display: "flex", gap: 5 }}>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data, userId: userData.user.userDetals?._id!, color: colorActive }))} disabled={quantityItem === data?.number}><FaPlus /></Button>
                        <div style={{ fontSize: "25px", width: "30px", textAlign: "center" }}>
                            {quantityItem}
                        </div>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data, userId: userData.user.userDetals?._id!, color: colorActive }))} ><FaMinus /></Button>
                    </div>
                }
            </div>
            <div>
                <div>
                    {favorite ?
                        <div>
                            <Button bsPrefix="BtnBNBIconFavorite" onClick={AddFavorite}><FaHeart size={"23px"} /></Button>
                        </div>
                        :
                        <div>
                            <Button bsPrefix="BtnBNBIconFavorite" onClick={AddFavorite}><FaRegHeart size={"23px"} /></Button>
                        </div>
                    }
                </div>
            </div>
        </div>
        <Alert show={showAlertAdd} variant='success' onClose={() => setShowAlertAdd(false)} style={{ position: "fixed", top: "30px", right: "50px" }} dismissible>
            <Alert.Heading>{t("Market.Added to favourites")}</Alert.Heading>
            {t("Market.The product has been added to favourites.")}
        </Alert>
        <Alert show={showAlertDis} variant='success' onClose={() => setShowAlertDis(false)} style={{ position: "fixed", top: "30px", right: "50px" }} dismissible>
            <Alert.Heading>{t("Market.Removed to favourites")}</Alert.Heading>
            {t("Market.Product removed to favourites")}
        </Alert>
    </>)
}

export default InfoProductClient;