import React, { useState } from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import './compennts.css';
import { Link, useNavigate } from 'react-router-dom';
import Langu from '../languge/languge';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';



const SearchBar = () => {
    const { t, i18n } = useTranslation();
    const userData = useSelector((state: RootState) => state.userData)
    const role = userData?.user?.userDetals?.role
    const nav = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const fetchSearchResults = async (value: any) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutSearchBarProducts`, {
                search: value,
                page: 1,
                limit: 10
            });
            return response.data.movies;
        } catch (error) {
            console.error('Error fetching search results:', error);
            return [];
        }
    };

    const onSuggestionsFetchRequested = async ({ value }: SuggestionsFetchRequestedParams) => {
        const results = await fetchSearchResults(value);
        setSearchResults(results);
    };

    const onSuggestionsClearRequested = () => {
        setSearchResults([]);
    };

    const getSuggestionValue = (suggestion: { product: { en: string, ar: string, he: string }, category: { en: string, ar: string, he: string } }) => i18n.language === "ar" ? suggestion.product.ar : i18n.language === "en" ? suggestion.product.en : suggestion.product.he;

    const renderSuggestion = (suggestion: { product: { en: string, ar: string, he: string }, category: { en: string, ar: string, he: string }, file: [string] }) => (
        <Link style={{ padding: '0', margin: "0", width: "100%", textDecoration: "none", zIndex: 14, position: "relative" }}
            to={role === true ? `/admin/market/search/${suggestion.product.en}` :
                (role === false ? `/client/market/search/${suggestion.product.en}` :
                    `/guest/market/search/${suggestion.product.en}`)}
        >
            <div style={{ display: "flex", alignItems: "center", color: "black", textDecoration: "none", width: "100%", zIndex: 14, position: "relative", minHeight: 50 }}>
                <img
                    src={`${process.env.REACT_APP_URL_API}/API/files/Products/${suggestion.file[0]}`}
                    alt="item image"
                    className="card-img-top"
                    style={{ width: "50px", marginLeft: "30px" }} />
                <div style={{ color: 'black', textDecoration: "none", width: "100%" }}>
                    <Langu {...suggestion.product} />
                </div>
            </div>
        </Link>
    );

    const BtnSearch = (event: React.FormEvent) => {
        event.preventDefault();
        nav(role === true ? `/admin/market/search/${searchTerm}` :
            (role === false ? `/client/market/search/${searchTerm}` :
                `/guest/market/search/${searchTerm}`))
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            BtnSearch(event as any);
        }
    };

    const inputProps = {
        placeholder: t('Hedar.Search'),
        value: searchTerm,
        onChange: (event: any, { newValue }: any) => setSearchTerm(newValue),
        onKeyDown: handleKeyDown
    };
    return (<>
        <form style={{ width: "100%", display: "flex", zIndex: 14, position: "relative" }} onSubmit={BtnSearch}>
            <Autosuggest
                suggestions={searchResults}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        </form>
    </>
    );
};

export default SearchBar;
