import { useContext, useEffect, useState } from "react";
import Langu from "../../components/languge/languge";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router";
import { Product } from "../../Interfaces";
import { ChatContext } from "../../components/context/ChatContext";


const YouMayAlsoLikeGuest: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!

    const [dataProducts, setDataProducts] = useState<Product[]>()
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts`)
            .then((doc) => setDataProducts(doc.data))
            .catch(err => console.log(err))
    }, [])

    return (<>
        <div style={{ borderBottom: "1px solid #dddddd", width: "100%" }}>
            {
                dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 3).map((Product, index) => (
                    <div
                        key={index}
                        onClick={() => nav(`/guest/market/productDetails/${Product?._id}`)}
                        style={{ margin: "10px 0" }}
                    >
                        <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "row", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                            <div className="cardYouMayAlsoLike" style={{ borderRadius: "5px" }}>
                                <img draggable={false} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                                <img draggable={false} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                            </div>
                            {
                                Product.offers &&
                                <div style={{ position: "absolute", top: "10px", left: Product.disable ? "90px" : "0px", backgroundColor: "red", borderRadius: "15px", padding: "10px", color: "white", width: "50px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>
                                    {Product.discount! * -1} %
                                </div>
                            }
                            {
                                Product.disable &&
                                <div style={{ position: "absolute", top: "10px", left: "0", backgroundColor: "black", borderRadius: "15px", padding: "10px", color: "white", width: "80px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "10px" }}>
                                    {t("Market.Sold out")}
                                </div>
                            }
                            <div>
                                <div style={{ fontSize: "15px", padding: "5px", maxWidth: 130 }}><Langu {...Product.product!} /></div>
                                <div style={{ width: "100%", fontSize: "12px" }}>
                                    {
                                        Product.newPrice ?
                                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                                    {Math.round(Number(Product.price) * baseCurrency) + ' ₺'}
                                                </div>
                                                <div style={{ color: "red" }}>
                                                    {Math.round(Number(Product.newPrice) * baseCurrency) + ' ₺'}
                                                </div>
                                            </div>
                                            :
                                            <div style={{ textAlign: "center", color: "gray" }}>
                                                {Math.round(Number(Product.price) * baseCurrency) + ' ₺'}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    </>)
}

export default YouMayAlsoLikeGuest;