import axios from "axios";
import { useState } from "react";
import './Home.admin.css'
import Offer1Admin from "./Offer1.admin";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AllProductsAdmin from "./AllProducts.admin";




const HomeAdmin: React.FC = () => {


    return (<>
        <div>
            <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Offer1Admin />
                </div>
                <div>
                    <AllProductsAdmin />
                </div>
            </div>
        </div>
    </>)
}

export default HomeAdmin;