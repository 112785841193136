import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router';
import Home from './guest/homeGuest/HomeGuest';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeAdmin from './admin/homeAdmin/HomeAdmin';
import OutletGuest from './guest/outlet/OutletGuest';
import OutletAdmin from './admin/outlet/OutletAdmin';
import CreateCategory from './admin/market/createCategory/CreateCategory';
import CreateProduct from './admin/market/createProduct/CreateProduct';
import CreateOffer from './admin/market/createOffer/CreateOffer';
import CreateUser from './users/createuser/CreateUser';
import CategoryGuest from './guest/market/category/Category.Guest';
import RefreshPage from './components/refreshPage/ReftrshPage';
import OutletClient from './client/outlet/OutletClient';
import HomeClient from './client/homeClient/HomeClient';
import { ValiedTokenAdmin, ValiedTokenClient } from './users/valiedToken/ValiedToken';
import CategoryClient from './client/market/category/Category.client';
import UpdataProduct from './admin/market/updateProduct/UpdataProduct';
import Favorites from './client/market/Favorites/Favorites';
import CategoryAdmin from './admin/market/category/Category.admin';
import PayPage from './client/market/payPage/PayPage';
import CompleteOrder from './client/market/payPage/CompleteOrder';
import GetOrdersAdmin from './admin/market/orders/getOrders/GetOrders.admin';
import Dashboard from './admin/Dashboard/Dashboard';
import UserId from './admin/Dashboard/userId.admin';
import GetOrderAdmin from './admin/market/orders/getOrder/GetOrder.admin';
import SearchPageGuest from './guest/market/searchPage/SearchPageGuest';
import SearchPageClient from './client/market/searchPage/SearchPageClient';
import MyOrders from './client/MyAccount/MyOrders';
import OrderDetalsClient from './client/MyAccount/OrderDetals.client';
import Invoice from './client/market/payPage/Invoice';
import AccountDetals from './client/MyAccount/AccountDetals';
import ChangeEmail from './client/MyAccount/ChangeEmail';
import Chat from './components/Chat/Chat';
import AccountDetalsAdmin from './admin/myAccount/AccountDetals';
import ForgotPasswordPage from './users/forgotPassword/ForgotPasswordPage';
import ProductDetails from './guest/component/ProductDetails/ProductDetails';
import ProductDetailsClient from './client/components/ProductDetailsClient/ProductDetailsClient';
import SearchPageAdmin from './admin/market/searchPageAdmin/searchPageAdmin';
import UpDateCategory from './admin/market/category/UpDateCategory';
import InvoiceForPrinting from './admin/market/orders/getOrder/InvoiceForPrinting';

const App: React.FC = () => {

  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate to="/admin/homePage" replace />} />

        <Route path='/guest' element={<OutletGuest />} >
          <Route index path='/guest/homePage' element={<Home />} />
          <Route path='/guest/createUser/:token' element={<CreateUser />} />
          <Route path='/guest/category/:name' element={<CategoryGuest />} />
          <Route path='/guest/category/:name/:type/:value' element={<CategoryGuest />} />
          <Route path='/guest/ForgotPasswordPage/:token' element={<ForgotPasswordPage />} />
          <Route path='/guest/market'>
            <Route path='/guest/market/productDetails/:id' element={<ProductDetails />} />
            <Route path='/guest/market/search/:product' element={<SearchPageGuest />} />
          </Route>
        </Route>

        <Route element={<RefreshPage />}>
          <Route element={<ValiedTokenClient />}>
            <Route path='/client' element={<OutletClient />}>
              <Route index path='/client/homePage' element={<HomeClient />} />
              <Route path='/client/myAccount'>
                <Route path='/client/myAccount/myOrders' element={<MyOrders />} />
                <Route path='/client/myAccount/orderDetals/:id' element={<OrderDetalsClient />} />
                <Route path='/client/myAccount/AccountDetals/:id' element={<AccountDetals />} />
                <Route path='/client/myAccount/changeEmail/:token/:id' element={<ChangeEmail />} />
              </Route>
              <Route path='/client/Chat' element={<Chat />} />
              <Route path='/client/market'>
                <Route path='/client/market/category/:name' element={<CategoryClient />} />
                <Route path='/client/market/category/:name/:type/:value' element={<CategoryClient />} />
                <Route path='/client/market/productDetails/:id' element={<ProductDetailsClient />} />
                <Route path='/client/market/Favorite/:id' element={<Favorites />} />
                <Route path='/client/market/payPage' element={<PayPage />} />
                <Route path='/client/market/ompleteOrder' element={<CompleteOrder />} />
                <Route path='/client/market/invoice/:id' element={<Invoice />} />
                <Route path='/client/market/search/:product' element={<SearchPageClient />} />
              </Route>
            </Route>
          </Route>

          <Route element={<ValiedTokenAdmin />}>
            <Route path='/admin' element={<OutletAdmin />}>
              <Route path='/admin/chat' element={<Chat />} />

              <Route path='/admin/dashboard' element={<Dashboard />} />
              <Route path='/admin/dashboard/userId/:id' element={<UserId />} />
              <Route index path='/admin/homePage' element={<HomeAdmin />} />
              <Route path='/admin/myAccount'>
                <Route path='/admin/myAccount/AccountDetals/:id' element={<AccountDetalsAdmin />} />
                <Route path='/admin/myAccount/changeEmail/:token/:id' element={<ChangeEmail />} />
              </Route>
              <Route path='/admin/market'>
                <Route path='/admin/market/createCategory' element={<CreateCategory />} />
                <Route path='/admin/market/createProduct' element={<CreateProduct />} />
                <Route path='/admin/market/updateProduct/:id' element={<UpdataProduct />} />
                <Route path='/admin/market/createOffer' element={<CreateOffer />} />
                <Route path='/admin/market/category/:name' element={<CategoryAdmin />} />
                <Route path='/admin/market/updateCategory/:id' element={<UpDateCategory />} />
                <Route path='/admin/market/category/:name/:type/:value' element={<CategoryAdmin />} />
                <Route path='/admin/market/search/:product' element={<SearchPageAdmin />} />
                <Route path='/admin/market/orders'>
                  <Route path='/admin/market/orders/getOrders' element={<GetOrdersAdmin />} />
                  <Route path='/admin/market/orders/getOrder/:id' element={<GetOrderAdmin />} />
                  <Route path='/admin/market/orders/InvoiceForPrinting/:id' element={<InvoiceForPrinting />} />
                </Route>
              </Route>
              <Route path='/admin/users'>
                <Route path='/admin/users/getUsers' />
              </Route>
            </Route>
          </Route>
        </Route>

      </Routes >
    </>
  );
}

export default App;
