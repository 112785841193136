import { NavLink, useNavigate } from "react-router-dom";
import '../../guest/hedarGuest/HedarGuest.css'
import I18nAdmin from "./I18n.admin";
import { useTranslation } from "react-i18next";
import AccuntDropdownAdmin from "./AccuntDropdown.admin";
import NotificationsOrders from "./NotificationsOrders";
import SearchBar from "../../components/searchBar/SearchBar";
import img1 from '../../files/HomeGuest/شعار أبو أحمد معدل مقصوص.jpg'
import { Button } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../../components/context/ChatContext";
import { FaBars } from "react-icons/fa";
import { ThemeColor } from "../../theme";
import axios from "axios";
import Cookies from "universal-cookie";
import { isExpired } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setUser } from "../../redux/userSlice";
import img2 from '../../files/HomeGuest/شعار خلفية حمرة معدل.jpg'
import img3 from '../../files/HomeGuest/IconHome2.png'


const HedarAdmin: React.FC = () => {
    const { t } = useTranslation()
    const userData = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch();
    const nav = useNavigate()
    const cookie = new Cookies()
    const getTokenCookie = cookie.get("bearer");
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!
    const [menuVisible, setMenuVisible] = useState<boolean>(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    let TokenReducer = userData?.user?.token
    let IsExpiredReducerToken = isExpired(TokenReducer!)
    let RefreshToken = getTokenCookie?.refreshToken

    useEffect(() => {
        if (IsExpiredReducerToken) {
            axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + RefreshToken,
                }
            })
                .then((doc) => {
                    const token = doc.data.token;
                    const userDetals = doc.data.data
                    cookie.set("bearer", { token: token, refreshToken: RefreshToken, userDetals: userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                    dispatch(setUser({ token: token, refreshToken: RefreshToken, userDetals: userDetals }))
                })
                .catch((err) => {
                    console.log("err refreshPage : ", err);
                })
        }
    }, [IsExpiredReducerToken, RefreshToken, TokenReducer, dispatch, cookie])

    return (<>
        <nav style={{ minHeight: "12vh", width: "100%" }}>
            <div className="no-print" style={isMobile ? { position: "fixed", zIndex: 62, width: "100%", marginBottom: 500 } : {}}>
                <div className="NavHedar" style={{ alignItems: "none", flexDirection: "column", width: "100%" }}>
                    <div style={{ padding: isMobile ? "0" : "0 40px", width: "100%", maxWidth: "1665px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", backgroundColor: ThemeColor.bgColor(1), padding: isMobile ? 0 : "0 40px" }}>
                            {
                                isMobile ?
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            onClick={toggleMenu}
                                            style={{
                                                background: 'none',
                                                backgroundColor: menuVisible ? "#dacda3" : ThemeColor.bgColor(0.2),
                                                border: 'none',
                                                fontSize: '20px',
                                                color: menuVisible ? ThemeColor.bgColor(1) : "#dacda3",
                                                cursor: 'pointer',
                                                padding: "2px 10px"
                                            }}
                                        >
                                            <FaBars />
                                        </Button>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button style={{ backgroundColor: "white", border: "none", padding: 0 }} onClick={() => nav(`/admin/homePage`)} >
                                                <img style={{ maxHeight: 40, padding: 5, backgroundColor: ThemeColor.bgColor(1) }} src={img2} />
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                            <NavLink className={"NavLinkHedar"} to="/admin/homePage">{t("Home.home")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/dashboard">{t("Home.dashboard")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/createCategory">{t("Home.create Category")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/createProduct">{t("Home.create Product")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/createOffer">{t("Home.create Offer")}</NavLink>
                                            <NavLink className={"NavLinkHedar"} to="/admin/market/orders/getOrders">{t("Home.Orders")}</NavLink>
                                        </div>
                                    </div>
                            }
                            {menuVisible && (
                                <div style={{
                                    position: 'absolute',
                                    top: '60px',
                                    right: '0',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    padding: '10px',
                                    zIndex: 61
                                }}>
                                    <NavLink className={"NavLinkHedar"} to="/admin/homePage" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                        display: "flex",
                                        gap: 10,
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >
                                        {/* <img draggable={false} style={{ width: 20, height: 20 }} src={img3} /> */}
                                        <p style={{ margin: 0 }}>{t("Home.home")}</p>
                                    </NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/dashboard" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >{t("Home.dashboard")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/createCategory" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >{t("Home.create Category")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/createProduct" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >{t("Home.create Product")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/createOffer" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >{t("Home.create Offer")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/orders/getOrders" style={{
                                        textDecoration: 'none',
                                        color: 'black',
                                        fontWeight: '700',
                                        transition: 'color 0.3s',
                                    }}
                                        onClick={toggleMenu}
                                        onMouseEnter={(e) => e.currentTarget.style.color = '#ff9100'}
                                        onMouseLeave={(e) => e.currentTarget.style.color = 'black'}
                                    >{t("Home.Orders")}</NavLink>



                                    {/* إضافة المزيد من الروابط حسب الحاجة */}
                                </div>
                            )}

                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <div>
                                    <NotificationsOrders />
                                </div>
                                <div>
                                    <AccuntDropdownAdmin />
                                </div>
                                <div>
                                    <I18nAdmin />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "1640px", width: "100%", padding: "0 40px 20px 40px", alignItems: "center" }}>
                            {isMobile ?
                                <div style={{ display: "flex", justifyContent: "center", maxWidth: "1640px", width: "100%", alignItems: "center", marginTop: 5 }}>
                                    <div style={{ maxWidth: "100%", display: "flex", flexDirection: "column", width: "100%" }}>
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <div style={{ width: "100%" }}>
                                                <SearchBar />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "1640px", width: "100%", padding: "0 40px 20px 40px", alignItems: "center" }}>
                                    <div>
                                        <Button style={{ backgroundColor: "white", border: "none" }} onClick={() => nav(`/admin/homePage`)} >
                                            <img src={img1} />
                                        </Button>
                                    </div>
                                    <div style={{ width: "40%" }}>
                                        <SearchBar />
                                    </div>
                                    <div style={{ width: 200, display: "flex", justifyContent: "flex-end" }}>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>)
}

export default HedarAdmin;