import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import CreateBrand from "./CreateBrand";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import CreateCategorys from "./CreateCategorys";
import { ChatContext } from "../../../components/context/ChatContext";


const CreateCategory: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Home.create Category"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [nameEN, setNameEN] = useState<string>('')
    const [nameAR, setNameAR] = useState<string>('')
    const [nameHE, setNameHE] = useState<string>('')
    const [photo, setPhoto] = useState<File[]>([])
    const [errInput, setErrInput] = useState<string>('')
    const [statusCategory, setStatusCategory] = useState<string[]>([])
    const [classification, setClassification] = useState<string[]>([])
    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        setErrInput('')
    }, [nameEN, nameAR, nameHE, photo])


    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }


    const BtnCreate = async () => {
        setDisabledBtnCreate(true)
        setStatusBtnCreate(<LoadingBtn />)
        if (!nameEN || !nameAR || !nameHE) {
            setStatusBtnCreate(t("Home.create Category"))
            setDisabledBtnCreate(false)
            setErrInput(t("Market.Please enter data"))
            return;
        }
        if (photo.length == 0) {
            setStatusBtnCreate(t("Home.create Category"))
            setDisabledBtnCreate(false)
            setErrInput(t("Market.Please enter photo"))
            return;
        }

        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        updatedFormData.append("nameEN", nameEN)
        updatedFormData.append("nameAR", nameAR)
        updatedFormData.append("nameHE", nameHE)
        if (!statusCategory.includes("null")) {
            updatedFormData.append("statusCategory", JSON.stringify(statusCategory));
        }
        if (!classification.includes("null2")) {
            updatedFormData.append("classification", JSON.stringify(classification));
        }

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Products/PostCategory`, updatedFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(doc => console.log(doc))
            .catch(err => console.log(err))
        setStatusBtnCreate(t("Home.create Category"))
        setDisabledBtnCreate(false)
    }

    const handelStatusCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.value;
        setStatusCategory((prev) => {
            if (checked === "null") {
                if (!statusCategory.includes("null")) {
                    return ['null']
                } else {
                    return []
                }
            } else if (prev.includes(checked)) {
                return prev.filter((item) => item !== checked);
            }
            return [...prev, checked];
        });
    };

    const handelClassification = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.value;
        setClassification((prev) => {
            if (checked === "null2") {
                if (!classification.includes("null2")) {
                    return ['null2']
                } else {
                    return []
                }
            } else if (prev.includes(checked)) {
                return prev.filter((item) => item !== checked);
            }
            return [...prev, checked];
        });
    };

    const changeImageOrder = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photo];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhoto(newPhotos);
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: isMobile ? 10 : "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: isMobile ? "40px 10px" : "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Home.create Category")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Category name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setNameEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setNameAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="Türkçe" onChange={e => { setNameHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <div> {t(`Hedar.Notifications.status`)} : </div>
                    <Form.Check className="mb-3" onChange={(e) => console.log(e.target.checked)} style={{ display: "flex", gap: 20 }} >
                        <Form.Label htmlFor="null">بلا </Form.Label>
                        <Form.Check id="null" value={"null"} name="null" onChange={handelStatusCategory} multiple />
                        <Form.Label htmlFor="newStatus">{t('Market.newStatus')} </Form.Label>
                        <Form.Check id="newStatus" value={"newStatus"} onChange={handelStatusCategory} checked={statusCategory.includes("newStatus")} disabled={statusCategory.includes("null") || false} multiple />
                        <Form.Label htmlFor="asNew">{t('Market.asNew')} </Form.Label>
                        <Form.Check id="asNew" value={"asNew"} onChange={handelStatusCategory} checked={statusCategory.includes("asNew")} disabled={statusCategory.includes("null") || false} multiple />
                        <Form.Label htmlFor="used">{t('Market.used')}</Form.Label>
                        <Form.Check id="used" value={"used"} onChange={handelStatusCategory} checked={statusCategory.includes("used")} disabled={statusCategory.includes("null") || false} multiple />
                    </Form.Check>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <div> {t(`Hedar.Notifications.Usage`)} : </div>
                    <Form.Check className="mb-3" onChange={(e) => console.log(e.target.checked)} style={{ display: "flex", gap: 20 }} >
                        <Form.Label htmlFor="null2">بلا </Form.Label>
                        <Form.Check id="null2" value={"null2"} name="null2" onChange={handelClassification} multiple />
                        <Form.Label htmlFor="gaming">{t('Market.gaming')} </Form.Label>
                        <Form.Check id="gaming" value={"gaming"} name="gaming" onChange={handelClassification} checked={classification.includes("gaming")} disabled={classification.includes("null2") || false} multiple />
                        <Form.Label htmlFor="desktop">{t('Market.desktop')}</Form.Label>
                        <Form.Check id="desktop" value={"desktop"} name="desktop" onChange={handelClassification} checked={classification.includes("desktop")} disabled={classification.includes("null2") || false} multiple />
                    </Form.Check>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{t("Market.Photo")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple />
                    </Form.Group>
                    <div>
                        {photo.length > 0 &&
                            <div style={{ display: "flex", gap: "15%", height: 250 }}>
                                <h3>الصور التي تم اختيارها:</h3>
                                {photo.map((i, index) => (
                                    <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                                        <p>الصورة {index + 1}: {i.name}</p>
                                        <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" />
                                        <div style={{ display: "flex", gap: 10 }}>
                                            {index > 0 && (
                                                <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index - 1)}>
                                                    <FaArrowUp size={20} />
                                                </Button>
                                            )}
                                            {index < photo.length - 1 && (
                                                <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index + 1)}>
                                                    <FaArrowDown size={20} />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div style={{ color: "red" }}>{errInput}</div>
                    <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                </div>
            </div>
        </div>
        <div>
            <CreateCategorys />
        </div>
        <div>
            <CreateBrand />
        </div>
    </>)
}

export default CreateCategory;