import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../components/laoding/laoding";


interface ForgotPasswordModalinterface {
    show: boolean;
    setShowModalForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}


const ForgotPasswordModal: React.FC<ForgotPasswordModalinterface> = ({ show, setShowModalForgotPassword }) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState<string>('')
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [statusBtn, setStatusBtn] = useState<any>(t("Hedar.Forgot Password"))
    const [errmsgLogin, setErrMsgLogin] = useState<string>('')
    const [successSend, setSuccessSend] = useState<boolean>()

    const btnForgotPassword = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => {
        setSuccessSend(false)
        setDisabledBtn(true)
        setStatusBtn(<LoadingBtn />)
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Auth/SendForgotPassword`, { email })
            .then(doc => setSuccessSend(doc.data.status === "success"))
            .catch(err => setErrMsgLogin(err.response?.data?.errors?.[0].msg))
        setStatusBtn(t("Hedar.Forgot Password"))
        setDisabledBtn(false)
    }
    useEffect(() => {
        setErrMsgLogin('')
    }, [email])

    const handleKeyPressForgotPassword = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            btnForgotPassword(event);
        }
    };

    return (<>
        <Modal show={show} onHide={() => setShowModalForgotPassword(false)}>
            <Modal.Header className="CloseBtn" closeButton>
                <Modal.Title>{t("Hedar.Forgot Password")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>{t("Hedar.Email address")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressForgotPassword} type="email" placeholder="name@example.com" autoFocus onChange={e => setEmail(e.target.value)} />
                </Form.Group>
                {
                    errmsgLogin &&
                    <div style={{ color: "red", width: "100%", textAlign: "center", padding: "5px", backgroundColor: "#ffe9e9", borderRadius: "5px" }}>
                        {errmsgLogin}
                    </div>
                }
                {
                    successSend &&
                    <div style={{ color: "green", fontWeight: "500", width: "100%", textAlign: "center", padding: "5px", backgroundColor: "rgb(199 237 200)", borderRadius: "5px" }}>
                        {t("Hedar.Confirmation email has been sent.")}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={btnForgotPassword} bsPrefix="BtnBNB" disabled={disabledBtn}>
                    {statusBtn}
                </Button>
                <Button variant="secondary" onClick={() => setShowModalForgotPassword(false)} style={{ width: "100px" }}>
                    {t("Hedar.Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ForgotPasswordModal;