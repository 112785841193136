import axios from "axios"
import { useContext, useEffect, useState } from "react"
import CardProducts from "../component/CardProductsGuest"
import { useTranslation } from "react-i18next"
import { ThemeColor } from "../../theme"
import CardCategory from "../component/CardCategory"
import { Category, Product } from "../../Interfaces"
import { Button } from "react-bootstrap"
import { LoadingBtn } from "../../components/laoding/laoding"
import { ChatContext } from "../../components/context/ChatContext"


const AllProducts = () => {
    const { t } = useTranslation()
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const [dataCategorys, setDataCategorys] = useState<Category[]>()
    const [limit, setLimit] = useState(50);
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetLimitProduct/${limit}`)
            .then((doc) => { setDataProducts(doc.data) })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnViewMore(t("Market.View more"))
            })
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [limit])

    const BtnViewMore = () => {
        setStatusBtnViewMore(<LoadingBtn />)
        setLimit(limit + 20)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ fontSize: 35, fontWeight: 600, textAlign: "center", padding: 20, color: ThemeColor.text, textDecoration: "underline" }}>
                {t("Home.Our varieties")}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: 1660, margin: isMobile ? "0 0 30px 0" : "0 0 100px 0", display: "flex", gap: isMobile ? 5 : 10, padding: isMobile ? "0 10px" : "0 40px", flexWrap: "wrap", justifyContent: "center" }}>
                    {dataCategorys?.map((category, index) => (
                        <CardCategory category={category} key={index} />
                    ))}
                    {dataProducts?.map((product, index) => (
                        <CardProducts Product={product} key={index} />
                    ))}
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: 40 }}>
                <Button bsPrefix="BtnBNB" onClick={BtnViewMore}>{statusBtnViewMore}</Button>
            </div>
        </div>
    </>)
}

export default AllProducts;