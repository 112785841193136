import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';

export interface CartItemProp {
    quantity: number;
    data: Record<string, any>;
    userId?: string;
    color?: string
}

export interface CounterState {
    value: number;
    name: string;
    cartItems: CartItemProp[]; // مصفوفة من الكائنات
}

// دالة لاسترجاع البيانات من اللوكل ستوريج
const loadStateFromLocalStorage = (): CartItemProp[] => {
    try {
        const serializedState = localStorage.getItem('cartItems');
        return serializedState ? JSON.parse(serializedState) : [];
    } catch (e) {
        console.error("Could not load state from localStorage", e);
        return [];
    }
}


const initialState: CounterState = {
    value: 0,
    name: "",
    cartItems: loadStateFromLocalStorage()
}

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        incrementItems: (state, action: PayloadAction<{ quantity: number, data: Record<string, any>, userId: string, color: string }>) => {
            const { quantity, data, userId, color } = action.payload;
            const existingItem = state.cartItems.find(item => ((item.data._id === data._id) && (item.color === color)));

            if (existingItem) {
                existingItem.quantity += quantity;
            } else {
                state.cartItems.push({ quantity, data, color });
            }

            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddCart/${userId}`, { quantity: existingItem ? existingItem.quantity : quantity, data: data, color },)
        },
        decrementItems: (state, action: PayloadAction<{ quantity: number, data: Record<string, any>, userId: string, color: string }>) => {
            const { quantity, data, userId, color } = action.payload;
            const existingItem = state.cartItems.find(item => ((item.data._id === data._id) && (item.color === color)));

            if (existingItem) {
                if (existingItem.quantity > quantity) {
                    existingItem.quantity -= quantity;
                    axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddCart/${userId}`, { quantity: existingItem.quantity, data: data, color })
                } else {
                    color ?
                        state.cartItems = state.cartItems.filter(item => ((item.data._id !== data._id) || (item.color !== color)))
                        :
                        state.cartItems = state.cartItems.filter(item => (item.data._id !== data._id));
                    axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutAddCart/${userId}`, { quantity: 0, data: data, color })
                }

                localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
            }
        },
        cleenItems: (state) => {
            state.cartItems = []
            localStorage.removeItem('cartItems');
        },
        decrement: (state) => {
            state.value -= 1
        },
        incrementByAmount: (state, action: PayloadAction<string>) => {
            state.name = action.payload
        },
    },
})

export const getItemQuantity = (state: CounterState, id: string, color: string): number => {
    return state.cartItems.find((item) => ((item.data._id === id) && (item.color === color)))?.quantity || 0;
}

export const { increment, decrement, incrementByAmount, incrementItems, decrementItems, cleenItems } = counterSlice.actions

export default counterSlice.reducer
