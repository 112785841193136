import img1 from '../../files/HomeGuest/شعار أبو أحمد معدل مقصوص.jpg'
import { FaInstagram } from "react-icons/fa";
import { LiaFacebook } from "react-icons/lia";
import { MdMailOutline } from "react-icons/md";
import '../../admin/market/orders/getOrder/Order.css'
import { useContext } from 'react';
import { ChatContext } from '../context/ChatContext';



const Footer: React.FC = () => {
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!

    return (<>
        <div className="no-print">
            {
                isMobile ?
                    <div style={{ backgroundColor: "white", borderTop: "2px solid black", borderTopLeftRadius: 30, borderTopRightRadius: 30, display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "center", padding: "0 10px", maxWidth: 1660, width: "100%", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "100%", textAlign: "center", fontSize: 14 }}>
                                    Copyright © 2024      Made by HLDSH TECH  -  All Rights Reserved.
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-around", width: "100%", marginTop: 5 }}>
                                    <div style={{ color: "blue" }}>
                                        Refund policy
                                    </div>
                                    <div style={{ color: "blue" }}>
                                        Terms and Conditions
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <img src={img1} style={{ maxWidth: "40%" }} />

                                <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                                    <div onClick={() => console.log("Instagram")} style={{ cursor: "pointer" }}>
                                        <FaInstagram size={30} color='#fe028c' />
                                    </div>
                                    <div onClick={() => console.log("Facebook")} style={{ cursor: "pointer" }}>
                                        <LiaFacebook size={30} color='#0866ff' />
                                    </div>
                                    <div onClick={() => console.log("Mail")} style={{ cursor: "pointer" }}>
                                        <MdMailOutline size={30} color='#ea4335' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ minHeight: 200, backgroundColor: "white", borderTop: "2px solid black", borderTopLeftRadius: 30, borderTopRightRadius: 30, display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0 40px", maxWidth: 1660, width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <img src={img1} style={{ maxWidth: "40%" }} />
                                <div>
                                    Copyright 2024      Made by HLDSH TECH  -  All Rights Reserved               Refund policy              Terms and Conditions
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: 15, padding: isMobile ? 0 : 35 }}>
                                <div onClick={() => console.log("Instagram")} style={{ cursor: "pointer" }}>
                                    <FaInstagram size={30} color='#fe028c' />
                                </div>
                                <div onClick={() => console.log("Facebook")} style={{ cursor: "pointer" }}>
                                    <LiaFacebook size={30} color='#0866ff' />
                                </div>
                                <div onClick={() => console.log("Mail")} style={{ cursor: "pointer" }}>
                                    <MdMailOutline size={30} color='#ea4335' />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    </>)
}

export default Footer;