import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router"
import { RootState } from "../../redux/store"



export function ValiedTokenAdmin() {
    const userData = useSelector((state: RootState) => state.userData)
    const user = userData.user?.userDetals
    const location = useLocation()
    return user?.role === true ? <Outlet /> : user?.role === false ? <Navigate state={{ frome: location }} replace to="/client/homePage" /> : <Navigate state={{ frome: location }} replace to="/guest/homePage" />
}

export function ValiedTokenClient() {
    const userData = useSelector((state: RootState) => state.userData)
    const user = userData.user?.userDetals
    const location = useLocation()
    return user?.role === false ? <Outlet /> : user?.role === true ? <Navigate state={{ frome: location }} replace to="/admin/homePage" /> : <Navigate state={{ frome: location }} replace to="/guest/homePage" />
}