import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../components/laoding/laoding";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface ModalXLProps {
    show: boolean;
    setShowModalChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
    userId: string;
}

const ModaleChangePassword: React.FC<ModalXLProps> = ({ show, setShowModalChangePassword, userId }) => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()

    const [oldPassword, setOldPassword] = useState<string>()
    const [newPassword, setNewPassword] = useState<string>()
    const [confirmPassword, setConfirmPassword] = useState<string>()
    const [statusBtn, setStatusBtn] = useState<any>(t("Home.Account.change"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState<string>()

    useEffect(() => {
        setErrMsg('')
    }, [oldPassword, newPassword, confirmPassword, show])

    const dataChangePassword = { oldPassword, newPassword, confirmPassword }
    const BtnChange = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>) => {
        setStatusBtn(<LoadingBtn />)
        setDisabledBtn(true)
        if (newPassword !== confirmPassword) {
            setErrMsg(t("Home.Account.Passwords do not match"))
            setDisabledBtn(false)
            setStatusBtn(t("Home.Account.change"))
            return;
        }
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Auth/ChangePassword/${userId}`, dataChangePassword,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => console.log(doc))
            .catch((err) => setErrMsg(err?.response?.data?.errors[0]?.msg))
        setDisabledBtn(false)
        setStatusBtn(t("Home.Account.change"))
    }

    const handleKeyPressLogin = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            BtnChange(event);
        }
    };

    return (<>
        <Modal show={show} onHide={() => setShowModalChangePassword(false)}>
            <Modal.Header className="CloseBtn" closeButton>
                <Modal.Title>
                    {t("Home.Account.change Password")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{t("Home.Account.OldPassword")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="password" placeholder="" autoFocus onChange={e => setOldPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                    <Form.Label>{t("Home.Account.NewPassword")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="password" placeholder="" onChange={e => setNewPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                    <Form.Label>{t("Home.Account.ConfirmPassword")} :</Form.Label>
                    <Form.Control onKeyDown={handleKeyPressLogin} type="password" placeholder="" onChange={e => setConfirmPassword(e.target.value)} />
                </Form.Group>
                {errMsg && <div style={{ padding: "10px", color: "red", backgroundColor: "#ffebeb", textAlign: "center" }}>{errMsg}</div>}
            </Modal.Body>
            <Modal.Footer >
                <Button bsPrefix="BtnBNB" onClick={BtnChange} disabled={disabledBtn}>{statusBtn}</Button>
                <Button variant="secondary" onClick={() => setShowModalChangePassword(false)}>
                    {t("Hedar.Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ModaleChangePassword;